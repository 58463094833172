import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { 
  ArrowRightIcon, 
  ChevronLeft, 
  ChevronRight, 
  Plus, 
  Users, 
  BookOpen, 
  BarChart, 
  Brain, 
  LucideGalleryVertical, 
  Hand, 
  CreditCard, 
  SmileIcon 
} from 'lucide-react';
import Footer from '../../layout/Footer';

import heroBg from '../../../assets/images/edu/hero-bg.png';
import featureDirective from '../../../assets/images/edu/feature-directive.png';
import featureAcademic from '../../../assets/images/edu/feature-academic.png';
import featureProject from '../../../assets/images/edu/feature-project.png';
import featureAnalytics from '../../../assets/images/edu/feature-analytics.png';
import featureAdaptive from '../../../assets/images/edu/feature-adaptive.png';
import careerImage1 from '../../../assets/images/edu/career-1.png';
import careerImage2 from '../../../assets/images/edu/career-2.png';
import careerImage3 from '../../../assets/images/edu/career-3.png';
import directiveAIBg from '../../../assets/images/edu/directive-ai-bg.svg';
import academicBg from '../../../assets/images/edu/academic-bg.svg';
import growthBg from '../../../assets/images/edu/growth-bg.svg';
import eduLearning from '../../../assets/images/edu/edu-learning.png';
import eduSubjects from '../../../assets/images/edu/edu-subjects.png';

const EduEssentials = () => (
  <section className="mb-[150px] bg-white w-full py-12 lg:py-16">
    <div className="max-w-[1680px] mx-auto px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f] text-center mb-16">
        Edu essentials
      </h2>
      <div className="relative w-[1680px] h-[673px] mx-auto flex justify-between">
        <div className="relative w-[830px] h-[673px] rounded-[28px] overflow-hidden">
          <img 
            src={eduLearning} 
            alt="Learning levels visualization" 
            className="absolute inset-0 w-full h-full object-cover"
          />
          <div className="absolute inset-0">
            <div className="p-8 md:p-12">
              <h3 className="text-2xl md:text-3xl font-semibold text-white mb-4">For every level</h3>
              <p className="text-base md:text-lg text-white">
                From elementary to higher education, AGT Edu adapts to every learning stage. Whether you're a young student starting your journey or pursuing advanced studies, our platform evolves with your educational needs.
              </p>
            </div>
          </div>
        </div>
        
        <div className="relative w-[830px] h-[673px] rounded-[28px] overflow-hidden">
          <img 
            src={eduSubjects} 
            alt="Subject types visualization" 
            className="absolute inset-0 w-full h-full object-cover"
          />
          <div className="absolute inset-0">
            <div className="p-8 md:p-12">
              <h3 className="text-2xl md:text-3xl font-semibold text-white mb-4">For every subject</h3>
              <p className="text-base md:text-lg text-white">
                AGT Edu supports learning across all disciplines. From STEM to humanities, arts to professional studies, our platform provides specialized tools for every field of study.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const DeviceShowcase = () => {
  const [selectedOption, setSelectedOption] = useState('directive-ai');
  
  const showcaseContent = {
    'directive-ai': {
      title: 'DirectiveAI Mode',
      description: "Transform group discussions with real-time collaborative features. DirectiveAI Mode enables seamless interaction between students and educators, facilitating dynamic learning environments. With AI-powered moderation and intelligent discussion prompts, every conversation becomes a meaningful learning experience.",
      image: careerImage1,
      alt: 'DirectiveAI collaborative interface'
    },
    'academic': {
      title: 'AGT Academic',
      description: "Power up your research with advanced academic tools. From automated citation generation to comprehensive project planning, AGT Academic streamlines your scholarly workflow. Analyze research papers, manage references, and organize your academic projects with intelligent assistance.",
      image: careerImage2,
      alt: 'Academic research dashboard'
    },
    'growth': {
      title: 'AGT Growth',
      description: "Experience personalized learning paths designed for every educational level. Track progress, receive targeted recommendations, and optimize learning strategies with advanced analytics. AGT Growth ensures each student reaches their full potential through adaptive learning technology.",
      image: careerImage3,
      alt: 'Learning progress dashboard'
    }
  };

  return (
    <div className="bg-[#f5f5f7] rounded-[28px] w-full max-w-[1680px] mx-auto overflow-hidden">
      <div className="p-8 md:p-12">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16">
          <div>
            {Object.entries(showcaseContent).map(([key, value]) => (
              <div key={key} className="border-b border-gray-200">
                <button
                  onClick={() => setSelectedOption(key)}
                  className="w-full text-left py-4 flex justify-between items-center"
                >
                  <span className="text-2xl md:text-3xl font-semibold text-[#1d1d1f]">
                    {value.title}
                  </span>
                  <ChevronRight 
                    className={`w-5 h-5 transform transition-all duration-300 ${
                      selectedOption === key 
                        ? 'rotate-90 text-[#1d1d1f]' 
                        : 'rotate-0 text-[#1d1d1f]'
                    }`} 
                  />
                </button>
                
                <div 
                  className={`transition-all duration-500 ease-in-out overflow-hidden ${
                    selectedOption === key 
                      ? 'max-h-[800px] opacity-100 py-4' 
                      : 'max-h-0 opacity-0 py-0'
                  }`}
                >
                  <p className="text-[#1d1d1f] text-base md:text-lg">
                    {value.description}
                  </p>
                  
                  <div className="lg:hidden mt-6">
                    <div className="relative">
                      <div 
                        className="w-full h-64 rounded-lg shadow-lg bg-cover bg-center"
                        style={{ backgroundImage: `url(${value.image})` }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="hidden lg:block relative h-[600px]">
            {Object.entries(showcaseContent).map(([key, value]) => (
              <div
                key={key}
                className={`absolute inset-0 transition-all duration-500 ease-in-out ${
                  selectedOption === key
                    ? 'opacity-100 translate-x-0'
                    : 'opacity-0 translate-x-8'
                }`}
              >
                <div 
                  className="w-full h-full rounded-lg shadow-lg bg-cover bg-center"
                  style={{ backgroundImage: `url(${value.image})` }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductComparison = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollRef = useRef(null);

  const handleScroll = (direction) => {
    const container = scrollRef.current;
    if (container) {
      const scrollAmount = direction * container.offsetWidth;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const products = [
    {
      name: "DirectiveAI Mode",
      tagline: "Real-time collaborative learning.",
      subtitle: "Transform group discussions.",
      description: "Enable seamless interaction between students.",
      features: [
        "Real-time group discussions",
        "AI-powered moderation",
        "Interactive learning sessions",
        "Dynamic collaboration tools"
      ],
      backgroundImage: directiveAIBg
    },
    {
      name: "AGT Academic",
      tagline: "Power up your research.",
      subtitle: "Streamline scholarly work.",
      description: "Advanced tools for academic excellence.",
      features: [
        "Automated citations",
        "Research paper analysis",
        "Project planning tools",
        "Reference management"
      ],
      backgroundImage: academicBg
    },
    {
      name: "AGT Growth",
      tagline: "Personalized learning paths.",
      subtitle: "Education that adapts to you.",
      description: "Tailored strategies for every educational level.",
      features: [
        "Adaptive learning paths",
        "Progress tracking",
        "Performance analytics",
        "Learning optimization"
      ],
      backgroundImage: growthBg
    }
  ];

  return (
    <div className="bg-[#f5f5f7] rounded-[28px] p-8 md:p-12 max-w-[1638px] mx-auto">
      <div className="hidden md:grid md:grid-cols-3 gap-8 md:gap-4 max-w-6xl mx-auto relative">
        {products.map((product, index) => (
          <div key={index} className="flex flex-col items-center text-center p-6">
            <div className="w-32 h-32 mb-6">
              <img 
                src={product.backgroundImage} 
                alt={product.name}
                className="w-full h-full object-contain"
              />
            </div>
            <span className="text-blue-600 text-sm font-medium mb-2">Included in AGT Edu</span>
            <h3 className="text-[#1d1d1f] text-xl font-semibold mb-1">{product.name}</h3>
            <p className="text-[#1d1d1f] text-sm mb-2">{product.subtitle}</p>
            <p className="text-[#1d1d1f] text-sm mb-6">{product.description}</p>
            <button className="text-blue-600 flex items-center text-sm hover:text-blue-700 transition-colors">
              Learn more <ArrowRightIcon className="ml-1 w-4 h-4" />
            </button>
            <div className="space-y-6 pt-8 border-t">
              {product.features.map((feature, idx) => (
                <div key={idx} className="flex flex-col items-center">
                  <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center mb-2">
                    <span className="text-[#1d1d1f]">•</span>
                  </div>
                  <p className="text-[#1d1d1f] text-sm">{feature}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="md:hidden relative">
        <div 
          ref={scrollRef}
          className="flex overflow-x-auto snap-x snap-mandatory scrollbar-hide"
          style={{ scrollSnapType: 'x mandatory' }}
        >
          {products.map((product, index) => (
            <div 
              key={index}
              className="flex-none w-full snap-center px-4"
              style={{ scrollSnapAlign: 'start' }}
            >
              <div className="flex flex-col items-center text-center">
                <div className="w-32 h-32 mb-6">
                  <img 
                    src={product.backgroundImage}
                    alt={product.name}
                    className="w-full h-full object-contain"
                  />
                </div>
                <span className="text-blue-600 text-sm font-medium mb-2">Included in AGT Edu</span>
                <h3 className="text-[#1d1d1f] text-xl font-semibold mb-1">{product.name}</h3>
                <p className="text-[#1d1d1f] text-sm mb-2">{product.subtitle}</p>
                <p className="text-[#1d1d1f] text-sm mb-6">{product.description}</p>
                <button className="text-blue-600 flex items-center text-sm hover:text-blue-700 transition-colors">
                  Learn more
                  <ArrowRightIcon className="ml-1 w-4 h-4" />
                </button>
                <div className="space-y-6 pt-8 border-t w-full">
                  {product.features.map((feature, idx) => (
                    <div key={idx} className="flex flex-col items-center">
                      <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center mb-2">
                        <span className="text-[#1d1d1f]">•</span>
                      </div>
                      <p className="text-[#1d1d1f] text-sm">{feature}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-6 space-x-2">
          <button
            onClick={() => handleScroll(-1)}
            className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
          >
            <ChevronLeft className="w-4 h-4" />
          </button>
          <button
            onClick={() => handleScroll(1)}
            className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
          >
            <ChevronRight className="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

const WhyAgtCard = ({ card, index, isFirst }) => (
  <motion.div
    initial={{ opacity: 0, x: 20 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ delay: index * 0.2 }}
    className={`flex-none snap-center w-[280px] md:w-[405px] h-[313px] bg-white rounded-2xl p-8 relative shadow-sm hover:shadow-md transition-shadow ${isFirst ? 'ml-[max(calc((100vw-1440px)/2),24px)]' : ''
    }`}
    style={{
      scrollSnapAlign: 'center',
      letterSpacing: '-0.374px',
      lineHeight: '25px',
      fontSize: '17px',
      color: '#1d1d1f'
    }}
  >
    <div className="w-12 h-12 mb-6 text-[#1d1d1f]">
      {card.icon}
    </div>
    <h3 className="text-2xl font-semibold mb-2 text-[#1d1d1f]">{card.title}</h3>
    <p className="text-[#1d1d1f] mb-4">{card.description}</p>
    <button className="absolute bottom-8 text-blue-600 hover:underline flex items-center">
      {card.link} <ChevronRight className="ml-1 w-4 h-4" />
    </button>
  </motion.div>
);

const AGTEdu = () => {
  const featuresRef = useRef(null);
  const whyAgtRef = useRef(null);

  const handleScroll = (ref, direction) => {
    if (ref.current) {
      const cardWidth = window.innerWidth < 640 ? 280 : 405;
      const scrollAmount = cardWidth + 32;
      
      ref.current.scrollBy({
        left: direction * scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  const featureCards = [
    {
      title: "Real-time Learning",
      subtitle: "Transform group discussions with DirectiveAI Mode",
      category: "DirectiveAI Mode",
      backgroundImage: featureDirective
    },
    {
      title: "Research Assistant",
      subtitle: "Streamline your research process",
      category: "AGT Academic",
      backgroundImage: featureAcademic
    },
    {
      title: "Project Management",
      subtitle: "Organize and plan your academic work",
      category: "AGT Academic",
      backgroundImage: featureProject
    },
    {
      title: "Learning Analytics",
      subtitle: "Track and optimize your educational journey",
      category: "AGT Growth",
      backgroundImage: featureAnalytics
    },
    {
      title: "Adaptive Learning",
      subtitle: "Personalized education paths",
      category: "AGT Growth",
      backgroundImage: featureAdaptive
    }
  ];

  const whyAgtCards = [
    {
      icon: <Users className="w-full h-full" />,
      title: "Collaborative Learning",
      description: "Enable dynamic group discussions and interactive learning sessions with DirectiveAI Mode.",
      link: "Explore collaboration"
    },
    {
      icon: <BookOpen className="w-full h-full" />,
      title: "Academic Excellence",
      description: "Streamline research and citations with advanced academic tools.",
      link: "See academic tools"
    },
    {
      icon: <Brain className="w-full h-full" />,
      title: "Adaptive Learning",
      description: "Experience education that adapts to your unique learning style.",
      link: "Learn about adaptation"
    },
    {
      icon: <BarChart className="w-full h-full" />,
      title: "Progress Tracking",
      description: "Monitor and optimize your learning journey with detailed analytics.",
      link: "View analytics"
    },
    {
      icon: <LucideGalleryVertical className="w-full h-full" />,
      title: "Complete Solution",
      description: "All educational tools seamlessly integrated in one platform.",
      link: "See integration"
    },
    {
      icon: <Hand className="w-full h-full" />,
      title: "Interactive Learning",
      description: "Engage with dynamic content and collaborative features.",
      link: "Try interactive tools"
    },
    {
      icon: <CreditCard className="w-full h-full" />,
      title: "Institution Ready",
      description: "Deploy across educational institutions with enterprise-grade security.",
      link: "See institution features"
    },
    {
      icon: <SmileIcon className="w-full h-full" />,
      title: "Future of Learning",
      description: "Stay ahead with continuous updates and cutting-edge educational AI.",
      link: "See our roadmap"
    }
  ];

  return (
    <div className="w-full min-h-screen bg-white">
      <section className="relative pt-16 lg:pt-20 mb-[150px] px-4 sm:px-6 lg:px-8">
        <div className="max-w-[1625px] mx-auto">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mb-6 md:mb-8"
          >
            <h1 className="text-4xl md:text-5xl lg:text-[80px] font-semibold text-[#1d1d1f]">
              AGT Edu
            </h1>
            <p className="text-lg md:text-xl lg:text-[28px] text-[#1d1d1f] mt-2">
              Learn. Grow. Transcend.
            </p>
          </motion.div>

          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <motion.div 
              className="h-[763px] relative w-full rounded-[28px] overflow-hidden bg-cover bg-center"
              style={{backgroundImage: `url(${heroBg})`}}
            >
              <div className="absolute bottom-8 left-8 sm:bottom-12 sm:left-12">
                <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4">
                  <button className="w-full sm:w-auto bg-white text-[#1d1d1f] px-6 py-3 rounded-full hover:bg-white/90 text-base md:text-lg transition-colors">
                    Get Started
                  </button>
                  <button className="w-full sm:w-auto text-white hover:underline flex items-center justify-center text-base md:text-lg transition-opacity">
                    Learn more <ArrowRightIcon className="ml-2 w-4 h-4" />
                  </button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </section>

      <section className="mb-[150px] bg-white w-full py-12 lg:py-16">
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f] mb-[80px] text-center">
            Get to know Edu
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 lg:gap-12">
            <div className="flex flex-col items-center text-center">
              <div className="w-32 h-32 mb-6">
                <img 
                  src={directiveAIBg} 
                  alt="DirectiveAI Mode" 
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="mt-6 space-y-2">
                <span className="text-sm text-blue-600 font-medium">Featured</span>
                <h3 className="text-2xl font-semibold text-[#1d1d1f]">DirectiveAI Mode</h3>
                <p className="text-base text-[#1d1d1f]">Real-time collaborative learning</p>
                <p className="text-base text-[#1d1d1f]">Transform group discussions</p>
                <div className="flex justify-center space-x-4 mt-6">
                  <button className="text-blue-600 hover:underline text-sm">Learn more</button>
                  <button className="text-blue-600 hover:underline text-sm flex items-center">
                    Try now <ArrowRightIcon className="ml-1 w-4 h-4" />
                  </button>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center text-center">
              <div className="w-32 h-32 mb-6">
                <img 
                  src={academicBg} 
                  alt="AGT Academic" 
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="mt-6 space-y-2">
                <span className="text-sm text-blue-600 font-medium">Featured</span>
                <h3 className="text-2xl font-semibold text-[#1d1d1f]">AGT Academic</h3>
                <p className="text-base text-[#1d1d1f]">Advanced research tools</p>
                <p className="text-base text-[#1d1d1f]">Streamline your academic work</p>
                <div className="flex justify-center space-x-4 mt-6">
                  <button className="text-blue-600 hover:underline text-sm">Learn more</button>
                  <button className="text-blue-600 hover:underline text-sm flex items-center">
                    Try now <ArrowRightIcon className="ml-1 w-4 h-4" />
                  </button>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center text-center">
              <div className="w-32 h-32 mb-6">
                <img 
                  src={growthBg} 
                  alt="AGT Growth" 
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="mt-6 space-y-2">
                <span className="text-sm text-blue-600 font-medium">Featured</span>
                <h3 className="text-2xl font-semibold text-[#1d1d1f]">AGT Growth</h3>
                <p className="text-base text-[#1d1d1f]">Personalized learning paths</p>
                <p className="text-base text-[#1d1d1f]">Education that adapts to you</p>
                <div className="flex justify-center space-x-4 mt-6">
                  <button className="text-blue-600 hover:underline text-sm">Learn more</button>
                  <button className="text-blue-600 hover:underline text-sm flex items-center">
                    Try now <ArrowRightIcon className="ml-1 w-4 h-4" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-[150px]">
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 mb-[80px]">
          <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f]">
            Explore features
          </h2>
        </div>

        <div className="relative">
          <div 
            ref={featuresRef}
            className="flex snap-x snap-mandatory space-x-8 overflow-x-auto scrollbar-hide pb-8"
            style={{
              scrollSnapType: 'x mandatory',
              WebkitOverflowScrolling: 'touch'
            }}
          >
            {featureCards.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.2 }}
                className={`flex-none snap-center rounded-3xl text-white relative overflow-hidden w-[280px] md:w-[405px] h-[500px] md:h-[740px] group cursor-pointer bg-cover bg-center ${
                  index === 0 ? 'ml-[max(calc((100vw-1440px)/2),24px)]' : ''
                }`}
                style={{
                  scrollSnapAlign: 'center',
                  backgroundImage: `url(${feature.backgroundImage})`
                }}
              >
                <div className="absolute inset-0">
                  <div className="absolute top-3 sm:top-6 left-3 sm:left-6 right-3 sm:right-6 text-center">
                    <span className="text-[10px] sm:text-sm font-medium text-white">
                      {feature.category}
                    </span>
                    <h3 className="text-base sm:text-2xl font-semibold text-white mt-1 sm:mt-2">
                      {feature.title}
                    </h3>
                    <p className="text-xs sm:text-lg text-white mt-0.5 sm:mt-1">
                      {feature.subtitle}
                    </p>
                  </div>
                  <button className="absolute bottom-3 sm:bottom-6 right-3 sm:right-6 w-6 sm:w-10 h-6 sm:h-10 rounded-full bg-white/10 backdrop-blur-sm flex items-center justify-center transition-all group-hover:bg-white/20">
                    <Plus className="w-3 sm:w-6 h-3 sm:h-6 text-white" />
                  </button>
                </div>
              </motion.div>
            ))}
          </div>
          <div className="absolute right-4 sm:right-6 lg:right-8 -bottom-12 flex space-x-2">
            <button
              onClick={() => handleScroll(featuresRef, -1)}
              className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
            >
              <ChevronLeft className="w-4 h-4" />
            </button>
            <button
              onClick={() => handleScroll(featuresRef, 1)}
              className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
            >
              <ChevronRight className="w-4 h-4" />
            </button>
          </div>
        </div>
      </section>

      <section className="mb-[150px] bg-white w-full py-12 lg:py-16">
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f] text-center mb-16">
            Find your perfect learning tool
          </h2>
          <ProductComparison />
        </div>
      </section>

      <section>
        <div className="bg-[#f5f5f7] pt-[80px] pb-[150px] mb-[150px]">
          <div className="max-w-[1440px] mx-auto">
          <div className="px-4 sm:px-6 lg:px-8 mb-[80px]">
              <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f]">
                Why choose AGT Edu?
              </h2>
              <div className="flex justify-end">
                <button className="text-blue-600 hover:underline flex items-center">
                  Compare all features <ChevronRight className="ml-1 w-4 h-4" />
                </button>
              </div>
            </div>
          </div>

          <div className="relative">
            <div 
              ref={whyAgtRef}
              className="flex snap-x snap-mandatory space-x-8 overflow-x-auto scrollbar-hide pb-8"
              style={{
                scrollSnapType: 'x mandatory',
                WebkitOverflowScrolling: 'touch'
              }}
            >
              {whyAgtCards.map((card, index) => (
                <WhyAgtCard 
                  key={index} 
                  card={card} 
                  index={index} 
                  isFirst={index === 0}
                />
              ))}
            </div>
            <div className="absolute right-4 sm:right-6 lg:right-8 -bottom-12 flex space-x-2">
              <button
                onClick={() => handleScroll(whyAgtRef, -1)}
                className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
              >
                <ChevronLeft className="w-4 h-4" />
              </button>
              <button 
                onClick={() => handleScroll(whyAgtRef, 1)}
                className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
              >
                <ChevronRight className="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-[150px] bg-white w-full py-12 lg:py-16">
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f] text-center mb-16">
            Made for your career
          </h2>
          <DeviceShowcase />
        </div>
      </section>

      <EduEssentials />

      <section className="mb-[150px] bg-white w-full py-12 lg:py-16">
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f] mb-8">
            Ready to transform your learning?
          </h2>
          <p className="text-lg md:text-xl text-gray-600 mb-12 max-w-3xl mx-auto">
            Join thousands of educators and students who are already using AGT Edu to revolutionize their educational experience.
          </p>
          <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
            <button className="w-full sm:w-auto bg-blue-600 text-white px-8 py-3 rounded-full hover:bg-blue-700 transition-colors">
              Get started for free
            </button>
            <button className="w-full sm:w-auto text-blue-600 hover:underline flex items-center justify-center">
              Contact us for institution pricing <ArrowRightIcon className="ml-2 w-4 h-4" />
            </button>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default AGTEdu;