import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import { ArrowRightIcon, ChevronLeft, ChevronRight, Plus } from 'lucide-react';
import Footer from '../layout/Footer';

import systemicUnityBg from '../../assets/images/company/systemic-unity.png';
import humanPotentialBg from '../../assets/images/company/human-potential.png';
import visionaryLeadershipBg from '../../assets/images/company/visionary-leadership.png';
import ethicalIntegrationBg from '../../assets/images/company/ethical-integration.png';
import leadershipBg from '../../assets/images/company/leadership-card.png';
import integrationBg from '../../assets/images/company/integration-card.png';
import innovationBg from '../../assets/images/company/innovation-card.png';
import ethicsBg from '../../assets/images/company/ethics-card.png';
import seamlessBg from '../../assets/images/company/seamless-integration.png';
import intelligentBg from '../../assets/images/company/intelligent-connection.png';
import visionBg from '../../assets/images/company/vision-execution.png';
import securityBg from '../../assets/images/company/security-ethics.png';
import businessBg from '../../assets/images/company/business-impact.png';
import productBg from '../../assets/images/company/product-impact.png';
import cultureBg from '../../assets/images/company/culture-impact.png';
import heroVideo from '../../assets/videos/company/hero-background.mp4';

const Company = () => {
  const impactRef = useRef(null);
  
  const handleImpactScroll = (ref, direction) => {
    if (ref.current) {
      const cardWidth = window.innerWidth < 640 ? 280 : 800;
      const scrollAmount = cardWidth + 32;
      
      ref.current.scrollBy({
        left: direction * scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  const announcements = [
    {
      id: 'systemic-unity',
      name: 'Systemic Unity',
      tagline: 'Integration as Vision',
      description: 'Integration is not just a strategy, but our fundamental way of seeing the world. We believe that maximum potential emerges when systems work as a coherent whole.',
      background: systemicUnityBg
    },
    {
      id: 'human-potential',
      name: 'Human Potential',
      tagline: 'Amplifying Capabilities',
      description: 'We exist to amplify human capabilities, not to replace them. Each innovation must translate into tangible advancement for humanity.',
      background: humanPotentialBg
    },
    {
      id: 'visionary-leadership',
      name: 'Visionary Leadership',
      tagline: 'Creating the Future',
      description: 'We do not conform to following trends; we create them. We take responsibility for guiding global technological development with decisions that impact decades.',
      background: visionaryLeadershipBg
    },
    {
      id: 'ethical-integration',
      name: 'Ethical Integration',
      tagline: 'Ethics at the Core',
      description: 'Ethics is not an additional layer, but part of the core of each development. We seek that each technological advance strengthens the social fabric. We recognize that leadership implies a responsibility to all humanity.',
      background: ethicalIntegrationBg
    }
  ];

  const cardSections = [
    {
      title: "Empowering Transformation Through Unified Systems",
      cards: [
        {
          title: "Seamlessly integrated",
          description: "Control your entire ecosystem from a unified interface.",
          background: seamlessBg,
          link: "Learn more about Systemic Unity"
        },
        {
          title: "Intelligently connected",
          description: "Systems that work as one coherent whole.",
          background: intelligentBg,
          link: "Learn more about connectivity"
        },
        {
          title: "Run it with your vision",
          description: "Your business, amplified by our technology.",
          background: visionBg,
          link: "Learn more about implementation"
        },
        {
          title: "All with the security and ethics of DirectiveAI",
          background: securityBg,
          link: "Learn more about our security"
        }
      ]
    },
    {
      title: "Innovative Solutions That Redefine Possibilities",
      subtitle: "View solutions",
      cards: [
        {
          title: "Leadership",
          description: "Transform how you guide your organization with visionary insights.",
          background: leadershipBg,
          link: "Learn more"
        },
        {
          title: "Integration",
          description: "Connect and optimize your entire operational ecosystem.",
          background: integrationBg,
          link: "Learn more"
        },
        {
          title: "Innovation",
          description: "Stay ahead with cutting-edge technological advancement.",
          background: innovationBg,
          link: "Learn more"
        },
        {
          title: "Ethics",
          description: "Ensure responsible development and implementation.",
          background: ethicsBg,
          link: "Learn more"
        }
      ]
    }
  ];

  const impactCards = [
    {
      title: "Business Decisions",
      subtitle: "Unity and Purpose",
      background: businessBg,
      description: "Each initiative contributes to ecosystem unity and demonstrates clear impact on human empowerment."
    },
    {
      title: "Product Development",
      subtitle: "Perfect Integration",
      background: productBg,
      description: "Design oriented to establish new standards with ethical considerations from conception."
    },
    {
      title: "Organizational Culture",
      subtitle: "Systemic Thinking",
      background: cultureBg,
      description: "We foster systemic thinking and value human impact in every decision."
    }
  ];

  return (
    <div className="w-full min-h-screen bg-[#f5f5f7]">
      <section className="relative pt-16 lg:pt-20 mb-20 lg:mb-32 px-4 sm:px-6 lg:px-8">
        <div className="max-w-[1625px] mx-auto">
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <motion.div 
              className="h-[400px] md:h-[600px] lg:h-[763px] relative w-full rounded-[28px] overflow-hidden"
            >
              <video
                className="absolute inset-0 w-full h-full object-cover"
                autoPlay
                loop
                muted
                playsInline
                style={{ playbackRate: 2 }}
                onLoadedMetadata={(e) => {
                  e.target.playbackRate = 2;
                }}
              >
                <source src={heroVideo} type="video/mp4" />
              </video>
              <div className="absolute inset-0 flex flex-col justify-center px-6 md:px-12 lg:px-16">
                <motion.h1 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="text-4xl md:text-5xl lg:text-[56px] font-semibold text-white mb-4"
                >
                  DirectiveAI
                </motion.h1>
                <motion.p 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                  className="text-lg md:text-xl lg:text-[21px] text-white/90 mb-8 max-w-2xl"
                >
                  Unifying and enhancing human capability through an integrated technological ecosystem.
                </motion.p>
                <motion.div 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.4 }}
                  className="flex flex-col sm:flex-row items-center gap-4"
                >
                  <button className="w-full sm:w-auto bg-white text-black px-6 py-3 rounded-full hover:bg-white/90 text-base md:text-lg transition-colors">
                    Discover more
                  </button>
                  <button className="w-full sm:w-auto text-white hover:underline flex items-center justify-center text-base md:text-lg transition-opacity">
                    Learn more <ArrowRightIcon className="ml-2 w-4 h-4" />
                  </button>
                </motion.div>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </section>

      <section className="mb-20 lg:mb-32 w-full py-12 lg:py-16">
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f] mb-12 text-center">
            Our Core Values
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {announcements.map((announcement) => (
              <motion.div
                key={announcement.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="h-[799px] rounded-3xl overflow-hidden relative"
              >
                <div 
                  className="absolute inset-0 bg-cover bg-center bg-no-repeat"
                  style={{ backgroundImage: `url(${announcement.background})` }}
                >
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                  <div className="absolute bottom-0 left-0 p-8 text-white">
                    <h3 className="text-2xl font-semibold mb-2">
                      {announcement.name}
                    </h3>
                    <p className="text-lg font-medium mb-4">
                      {announcement.tagline}
                    </p>
                    <p className="text-white/90 text-base">
                      {announcement.description}
                    </p>
                    <button className="mt-6 text-white hover:underline flex items-center text-sm">
                      Learn more <ArrowRightIcon className="ml-2 w-4 h-4" />
                    </button>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {cardSections.map((section, sectionIndex) => (
        <section key={sectionIndex} className="mb-20 lg:mb-32 w-full py-12 lg:py-16">
          <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f] mb-12 text-center">
              {section.title}
            </h2>
            
            {section.subtitle && (
              <div className="text-center mb-8">
                <a href="#" className="text-[#007AFF] hover:text-[#005ECB] hover:underline text-sm inline-flex items-center justify-center">
                  {section.subtitle} <ArrowRightIcon className="inline-block ml-1 w-4 h-4" />
                </a>
              </div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {section.cards.map((card, index) => (
                <div 
                  key={index}
                  className="rounded-3xl h-[480px] relative overflow-hidden group"
                >
                  <div 
                    className="absolute inset-0 bg-cover bg-center bg-no-repeat"
                    style={{ backgroundImage: `url(${card.background})` }}
                  >
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                    <div className="absolute inset-0 p-8 flex flex-col justify-end text-white">
                      <h3 className="text-xl font-semibold mb-2">
                        {card.title}
                      </h3>
                      {card.description && (
                        <p className="text-white/90 mb-4">
                          {card.description}
                        </p>
                      )}
                      <a href="#" className="text-white hover:underline inline-flex items-center">
                        {card.link} <ArrowRightIcon className="ml-1 w-4 h-4" />
                      </a>
                    </div>
                  </div>
                  <div className="absolute top-4 right-4 w-10 h-10 rounded-full bg-white/10 flex items-center justify-center opacity-0 group-hover:opacity-0 transition-opacity">
                    <Plus className="w-6 h-6 text-white" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      ))}

      <section className="mb-20 lg:mb-32">
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 mb-12">
          <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f] mb-4">
            Impact that transcends
          </h2>
          <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f] mb-8">
            boundaries.
            <span className="block h-1 w-16 bg-cyan-400 mt-2"></span>
          </h2>
          <p className="text-[#1d1d1f] text-base md:text-lg max-w-3xl">
            Unifying and enhancing human capability through an integrated technological ecosystem, guiding humanity towards its next level of evolution.
          </p>
        </div>

        <div className="relative">
          <div 
            ref={impactRef}
            className="flex snap-x snap-mandatory space-x-8 overflow-x-auto scrollbar-hide pb-8 px-4 sm:px-6 lg:px-8"
            style={{
              scrollSnapType: 'x mandatory',
              WebkitOverflowScrolling: 'touch'
            }}
          >
            {impactCards.map((card, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.2 }}
                className={`flex-none snap-center w-[280px] md:w-[600px] lg:w-[800px] h-[400px] lg:h-[500px] rounded-3xl relative overflow-hidden group cursor-pointer ${index === 0 ? 'ml-[calc((100vw-1440px)/2)]' : ''}`}
                style={{
                  scrollSnapAlign: 'center'
                }}
              >
                <div 
                  className="absolute inset-0 bg-cover bg-center bg-no-repeat"
                  style={{ backgroundImage: `url(${card.background})` }}
                >
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                  <div className="absolute bottom-0 left-0 p-6 lg:p-8">
                    <h3 className="text-xl md:text-2xl font-semibold text-white mb-2">
                      {card.title}
                    </h3>
                    <p className="text-lg md:text-xl text-white mb-4">
                      {card.subtitle}
                    </p>
                    <p className="text-white/90 text-sm md:text-base max-w-xs">
                      {card.description}
                    </p>
                  </div>
                </div>
                <div className="absolute top-4 right-4 w-10 h-10 rounded-full bg-white/10 flex items-center justify-center opacity-0 group-hover:opacity-0 transition-opacity">
                  <Plus className="w-6 h-6 text-white" />
                </div>
              </motion.div>
            ))}
          </div>
          <div className="absolute right-4 sm:right-6 lg:right-8 -bottom-12 flex space-x-2">
            <button 
              onClick={() => handleImpactScroll(impactRef, -1)}
              className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
            >
              <ChevronLeft className="w-4 h-4" />
            </button>
            <button 
              onClick={() => handleImpactScroll(impactRef, 1)}
              className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
            >
              <ChevronRight className="w-4 h-4" />
            </button>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Company;