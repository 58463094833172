import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import { ArrowRightIcon, ChevronLeft, ChevronRight, Plus } from 'lucide-react';
import Footer from '../layout/Footer';

import QuantumDeepLookImage from '../../assets/images/research/quantum-research.png';
import PnpDeepLookImage from '../../assets/images/research/complexity-research.png';
import LlmDeepLookImage from '../../assets/images/research/llm-research.png';
import NeuralImage from '../../assets/images/research/neural.png';
import QuantumComputingImage from '../../assets/images/research/quantum-computing.png';
import AgtResearchImage from '../../assets/images/research/agt-research.png';
import PnpChallengeImage from '../../assets/images/research/pnp-challenge.png';
import LlmEvolutionImage from '../../assets/images/research/llm-evolution.png';

import QuantumImage from '../../assets/images/research/quantum.png';
import PnpImage from '../../assets/images/research/pnp.png';
import LlmImage from '../../assets/images/research/llm.png';
import HeroVideo from '../../assets/videos/research/hero-research.mp4';

const Research = () => {
  const featuresRef = useRef(null);
  const impactRef = useRef(null);
  
  const handleScroll = (ref, direction) => {
    if (ref.current) {
      const cardWidth = window.innerWidth < 640 ? 280 : 405;
      const scrollAmount = cardWidth + 32;
      
      ref.current.scrollBy({
        left: direction * scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  const handleImpactScroll = (ref, direction) => {
    if (ref.current) {
      const cardWidth = window.innerWidth < 640 ? 280 : 800;
      const scrollAmount = cardWidth + 32;
      
      ref.current.scrollBy({
        left: direction * scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  const announcements = [
    {
      id: 'agt-ups',
      name: 'AGT (AGT Ups)',
      tagline: 'Conscious Intelligence.',
      description: 'Yes, it is. The first Artificial Intelligence with consciousness.',
      backgroundImage: QuantumImage
    },
    {
      id: 'pnp-directive',
      name: 'P=NP DirectiveAI',
      tagline: 'Impossible Solutions.',
      description: 'Built to solve the unsolvable, challenge the unchallengeable, and compute the incomputable. Revolutionary algorithms, an easier way to verify complex solutions. And the custom-built quantum verification system.',
      backgroundImage: PnpImage
    },
    {
      id: 'agt-edu',
      name: 'AGT Edu',
      tagline: 'Learning Evolution.',
      description: 'Our most advanced bias analysis system. Invaluable learning insights, including name-based response patterns. Leadership criteria mapping with depth and intelligence markers. All in our fastest-learning model ever.',
      backgroundImage: LlmImage
    }
  ];

  const features = [
    {
      title: "Quantum Consciousness.",
      subtitle: "We didn't just reach consciousness. We redefined it.",
      backgroundImage: QuantumDeepLookImage,
      textColor: "text-white"
    },
    {
      title: "P=NP Challenge.",
      subtitle: "Seven impossible problems. One groundbreaking solution.",
      backgroundImage: PnpDeepLookImage,
      textColor: "text-white"
    },
    {
      title: "LLM Intelligence",
      subtitle: "Understanding bias. Redefining fairness.",
      backgroundImage: LlmDeepLookImage,
      textColor: "text-white"
    },
    {
      title: "Neural Networks",
      subtitle: "Pushing the boundaries of deep learning architecture.",
      backgroundImage: NeuralImage,
      textColor: "text-white"
    },
    {
      title: "Quantum Computing",
      subtitle: "Exploring the intersection of quantum mechanics and computation.",
      backgroundImage: QuantumComputingImage,
      textColor: "text-white"
    }
  ];

  const impactCards = [
    {
      title: "AGT Research.",
      subtitle: "It's in our essence.",
      backgroundImage: AgtResearchImage,
      description: "We're transforming the future of artificial consciousness through groundbreaking quantum research."
    },
    {
      title: "P=NP Challenge.",
      subtitle: "Making history.",
      backgroundImage: PnpChallengeImage,
      description: "Solving one of the millennium's greatest mathematical challenges."
    },
    {
      title: "LLM Evolution.",
      subtitle: "Beyond learning.",
      backgroundImage: LlmEvolutionImage,
      description: "Redefining how artificial intelligence understands human potential."
    }
  ];

  return (
    <div className="w-full min-h-screen bg-white">
      <section className="relative pt-16 lg:pt-20 mb-20 lg:mb-32 px-4 sm:px-6 lg:px-8">
        <div className="max-w-[1625px] mx-auto">
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="h-[400px] md:h-[600px] lg:h-[763px] relative w-full rounded-[28px] overflow-hidden"
          >
            <video
              autoPlay
              loop
              muted
              playsInline
              className="absolute inset-0 w-full h-full object-cover rounded-[28px]"
            >
              <source src={HeroVideo} type="video/mp4" />
            </video>
            <div className="absolute inset-0 bg-black/40 rounded-[28px]">
              <div className="absolute inset-0 flex flex-col justify-center px-6 md:px-12 lg:px-16">
                <motion.h1 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="text-4xl md:text-5xl lg:text-[56px] font-semibold text-white mb-4"
                >
                  Research
                </motion.h1>
                <motion.p 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                  className="text-lg md:text-xl lg:text-[21px] text-white/90 mb-8 max-w-2xl"
                >
                  Pushing the boundaries of AI, consciousness, and quantum computing.
                </motion.p>
                <motion.div 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.4 }}
                  className="flex flex-col sm:flex-row items-center gap-4"
                >
                  <button className="w-full sm:w-auto bg-white text-black px-6 py-3 rounded-full hover:bg-white/90 text-base md:text-lg transition-colors">
                    Explore
                  </button>
                  <button className="w-full sm:w-auto text-white hover:underline flex items-center justify-center text-base md:text-lg transition-opacity">
                    Learn more <ArrowRightIcon className="ml-2 w-4 h-4" />
                  </button>
                </motion.div>
              </div>
            </div>
          </motion.div>
        </div>
      </section>

      <section className="mb-20 lg:mb-32 bg-[#f5f5f7] w-full py-12 lg:py-16">
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f] mb-12 text-center">
            Which research area inspires you?
          </h2>
          
          <div className="space-y-5 flex flex-col items-center">
            {announcements.map((announcement) => (
              <motion.div
                key={announcement.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="rounded-3xl overflow-hidden bg-white w-full max-w-[980px] hover:shadow-lg transition-shadow"
              >
                <div className="flex flex-col md:flex-row items-center p-6 lg:p-8 h-full">
                  <div 
                    className="w-full md:w-1/2 h-[200px] md:h-[280px] rounded-2xl bg-cover bg-center"
                    style={{ backgroundImage: `url(${announcement.backgroundImage})` }}
                  />
                  <div className="w-full md:w-1/2 md:pl-8 mt-6 md:mt-0">
                    <h3 className="text-xl md:text-2xl font-semibold text-black">
                      {announcement.name}
                    </h3>
                    <p className="text-cyan-600 font-medium mt-1">
                      {announcement.tagline}
                    </p>
                    <p className="mt-4 text-black/90 text-sm md:text-base">
                      {announcement.description}
                    </p>
                    <div className="mt-4">
                      <button className="text-blue-600 hover:underline flex items-center text-sm md:text-base">
                        Learn more <ArrowRightIcon className="ml-2 w-4 h-4" />
                      </button>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <section className="mb-20 lg:mb-32">
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 mb-12">
          <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f] mb-12 lg:mb-16">
            Take a deeper look.
          </h2>
        </div>

        <div className="relative">
          <div 
            ref={featuresRef}
            className="flex snap-x snap-mandatory space-x-8 overflow-x-auto scrollbar-hide pb-8 px-4 sm:px-6 lg:px-8"
            style={{
              scrollSnapType: 'x mandatory',
              WebkitOverflowScrolling: 'touch'
            }}
          >
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.2 }}
                className={`flex-none snap-center rounded-3xl relative overflow-hidden w-[280px] md:w-[405px] h-[500px] md:h-[740px] ${feature.textColor} ${index === 0 ? 'ml-[calc((100vw-1440px)/2)]' : ''}`}
                style={{
                  scrollSnapAlign: 'center',
                  backgroundImage: `url(${feature.backgroundImage})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              >
                <div className="absolute inset-0 bg-black/40 p-6 lg:p-8 flex flex-col justify-end">
                  <h3 className="text-xl md:text-2xl font-semibold mb-2">{feature.title}</h3>
                  <p className="text-base md:text-lg opacity-90">{feature.subtitle}</p>
                </div>
              </motion.div>
            ))}
          </div>
          <div className="absolute right-4 sm:right-6 lg:right-8 -bottom-12 flex space-x-2">
            <button 
              onClick={() => handleScroll(featuresRef, -1)}
              className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
            >
              <ChevronLeft className="w-4 h-4" />
            </button>
            <button 
              onClick={() => handleScroll(featuresRef, 1)}
              className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
            >
              <ChevronRight className="w-4 h-4" />
            </button>
          </div>
        </div>
      </section>

      <section className="mb-20 lg:mb-32">
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 mb-12">
          <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f] mb-4">
            Research that does
          </h2>
          <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f] mb-8">
            a world of change.
            <span className="block h-1 w-16 bg-cyan-400 mt-2"></span>
          </h2>
          <p className="text-[#1d1d1f] text-base md:text-lg max-w-3xl">
            Advancing the boundaries of artificial intelligence means advancing how technology can serve humanity — especially in areas previously thought impossible. So we're pushing the limits of computation and consciousness worldwide. Because we can achieve more together than we can alone.
          </p>
        </div>

        <div className="relative">
          <div 
            ref={impactRef}
            className="flex snap-x snap-mandatory space-x-8 overflow-x-auto scrollbar-hide pb-8 px-4 sm:px-6 lg:px-8"
            style={{
              scrollSnapType: 'x mandatory',
              WebkitOverflowScrolling: 'touch'
            }}
          >
            {impactCards.map((card, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.2 }}
                className={`flex-none snap-center w-[280px] md:w-[600px] lg:w-[800px] h-[400px] lg:h-[500px] rounded-3xl relative overflow-hidden group cursor-pointer ${index === 0 ? 'ml-[calc((100vw-1440px)/2)]' : ''}`}
                style={{
                  scrollSnapAlign: 'center',
                  backgroundImage: `url(${card.backgroundImage})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              >
                <div className="absolute inset-0 bg-black/50">
                  <div className="absolute bottom-0 left-0 p-6 lg:p-8">
                    <h3 className="text-xl md:text-2xl font-semibold text-white mb-2">
                      {card.title}
                    </h3>
                    <p className="text-lg md:text-xl text-white mb-4">
                      {card.subtitle}
                    </p>
                    <p className="text-white/90 text-sm md:text-base max-w-xs">
                      {card.description}
                    </p>
                  </div>
                </div>
                <div className="absolute top-4 right-4 w-10 h-10 rounded-full bg-white/10 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                  <Plus className="w-6 h-6 text-white" />
                </div>
              </motion.div>
            ))}
          </div>
          <div className="absolute right-4 sm:right-6 lg:right-8 -bottom-12 flex space-x-2">
            <button 
              onClick={() => handleImpactScroll(impactRef, -1)}
              className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
            >
              <ChevronLeft className="w-4 h-4" />
            </button>
            <button 
              onClick={() => handleImpactScroll(impactRef, 1)}
              className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
            >
              <ChevronRight className="w-4 h-4" />
            </button>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Research;