import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const footerLinks = {
    'AGT': {
      'AGT Pro': '/agt-pro',
      'AGT Search Pro': '/search-pro',
      'AGT Maker Pro': '/maker-pro',
      'AGT Video': '/video',
    },
    'Research & Innovation': {
      'Research Papers': '/research',
      'Technology Blog': '/blog',
      'Consciousness AI': '/consciousness-ai',
      'AI Ethics': '/ethics',
      'Innovation Lab': '/lab',
      'Research Partnerships': '/partnerships'
    },
    'AGT Ups': {
      'AGT Product': '/product',
      'AGT Cash Flow': '/cash-flow',
      'AGT Supplier Finder': '/supplier-finder',
      'AGT Council': '/council',
      'AGT Growth': '/growth',
      'Success Stories': '/success-stories',
      'Integration Guide': '/integration-guide'
    },
    'AGT Edu': {
      'DirectiveAI Mode': '/directive-mode',
      'AGT Academic': '/academic',
      'AGT Growth': '/edu-growth',
      'Educational Pricing': '/edu-pricing'
    },
    'Support': {
      'Documentation': '/docs',
      'API Reference': '/api-docs',
      'Community Forum': '/community',
      'Knowledge Base': '/knowledge',
      'Video Tutorials': '/tutorials',
      'Contact Support': '/support'
    },
    'Company': {
      'About Us': '/about',
      'Leadership': '/leadership',
      'Careers': '/careers',
      'Press Room': '/press',
      'Events': '/events',
      'Contact': '/contact'
    },
    'Values & Commitments': {
      'AI Ethics': '/ai-ethics',
      'Environmental Impact': '/environment',
      'Privacy & Security': '/privacy',
      'Responsible AI': '/responsible-ai',
      'Research Integrity': '/integrity',
      'Open Source': '/open-source'
    }
  };

  return (
    <footer className="bg-[#f5f5f7] text-[#6e6e73] py-8" style={{
      fontFamily: '-apple-system, BlinkMacSystemFont, "SF Pro Text", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
      fontSize: '12px',
      letterSpacing: '-0.12px',
      lineHeight: '16.0005px'
    }}>
      <div className="max-w-[980px] mx-auto px-4">
        {/* Legal Text */}
        <div className="border-b border-[#d2d2d7] pb-8">
          <p className="mb-3 inline">
            AGT and related technologies are designed to enhance and augment human capabilities, not replace them. 
            Usage of AGT products requires acceptance of our AI ethics guidelines and responsible use policies.
          </p>
          <p className="mb-3 inline">
            A subscription is required for AGT Pro, AGT Ups, and AGT Edu. API usage limits and fair use policies apply.
          </p>
        </div>

        {/* Footer Links */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-x-12 gap-y-8 py-8">
          {Object.entries(footerLinks).map(([category, links]) => (
            <div key={category} className="footer-column">
              <h3 className="font-semibold text-[#1d1d1f] mb-3">{category}</h3>
              <ul className="space-y-2">
                {Object.entries(links).map(([label, href]) => (
                  <li key={label}>
                    <Link 
                      to={href}
                      className="inline hover:text-[#1d1d1f] transition-colors duration-300"
                    >
                      {label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Bottom Footer */}
        <div className="border-t border-[#d2d2d7] pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="mb-4 md:mb-0 inline">
              Copyright © 2024 DirectiveAI Inc. All rights reserved.
            </p>
            <div className="flex flex-wrap justify-center md:justify-end gap-x-4">
              <Link to="/privacy" className="inline hover:underline">Privacy Policy</Link>
              <Link to="/terms" className="inline hover:underline">Terms of Use</Link>
              <Link to="/responsible-ai" className="inline hover:underline">Responsible AI</Link>
              <Link to="/legal" className="inline hover:underline">Legal</Link>
              <Link to="/sitemap" className="inline hover:underline">Site Map</Link>
            </div>
          </div>
          <div className="mt-4 text-right">
            <span className="inline">United States</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;