import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/layout/Header';
import Home from './components/pages/Home';
import AGT from './components/pages/AGT';
import Research from './components/pages/Research';
import Company from './components/pages/Company';
import AGTEdu from './components/pages/AGTEdu';
import AGTUps from './components/pages/AGTUps';

function ScrollToTop() {
 const location = useLocation();
 
 useEffect(() => {
   window.scrollTo(0, 0);
 }, [location]);
 
 return null;
}

function TitleUpdater() {
 const location = useLocation();
 
 useEffect(() => {
   const pageTitles = {
     '/': 'DirectiveAI',
     '/agt': 'AGT - DirectiveAI',
     '/research': 'Research - DirectiveAI',
     '/company': 'Company - DirectiveAI',
     '/edu': 'AGT Edu - DirectiveAI',
     '/ups': 'AGT Ups - DirectiveAI'
   };
   
   document.title = pageTitles[location.pathname] || 'DirectiveAI';
 }, [location]);
 
 return null;
}

function App() {
 return (
   <Router>
     <div className="min-h-screen bg-black w-full overflow-x-hidden">
       <ScrollToTop />
       <TitleUpdater />
       <Header />
       <main className="bg-black">
         <Routes>
           <Route path="/" element={<Home />} />
           <Route path="/agt" element={<AGT />} />
           <Route path="/research" element={<Research />} />
           <Route path="/company" element={<Company />} />
           <Route path="/edu" element={<AGTEdu />} />
           <Route path="/ups" element={<AGTUps />} />
         </Routes>
       </main>
     </div>
   </Router>
 );
}

export default App;