import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ArrowRightIcon, ChevronLeft, ChevronRight, Plus } from 'lucide-react';
import Footer from '../layout/Footer';

import heroVideo from '../../assets/videos/home/agt-hero.mp4';
import heroBgImage from '../../assets/images/home/hero-bg.png';

import consciousAIImage from '../../assets/images/home/conscious-ai.png';
import agtUpsImage from '../../assets/images/home/agt-ups.png';
import agtEduImage from '../../assets/images/home/agt-edu.png';
import purposeImage from '../../assets/images/home/purpose.png';
import allInOneImage from '../../assets/images/home/all-in-one.png';

import searchProImage from '../../assets/images/home/search-pro.png';
import makerProImage from '../../assets/images/home/maker-pro.png';
import videoImage from '../../assets/images/home/video.png';
import academicImage from '../../assets/images/home/academic.png';
import growthImage from '../../assets/images/home/growth.png';
import productImage from '../../assets/images/home/product.png';
import cashFlowImage from '../../assets/images/home/cash-flow.png';
import councilImage from '../../assets/images/home/council.png';

const ButtonStyle = ({children, variant = 'light', onClick}) => (
  <button 
    onClick={onClick}
    className={`
      px-5 py-2.5 rounded-full
      ${variant === 'light' ? 'bg-white text-[#1d1d1f]' : 'bg-[#1d1d1f] text-white'}
      ${variant === 'light' ? 'hover:bg-white/90' : 'hover:bg-[#1d1d1f]/90'}
      transition-all duration-300
      text-[17px] font-medium
    `}
  >
    {children}
  </button>
);

const VideoWithFallback = () => {
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const videoRef = useRef(null);

  const handleVideoLoad = () => {
    if (videoRef.current && videoRef.current.readyState >= 3) {
      videoRef.current.play()
        .then(() => setIsVideoReady(true))
        .catch(() => setIsVideoReady(false));
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isVideoReady) {
        setShowVideo(true);
        setTimeout(() => setShowContent(true), 900);
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, [isVideoReady]);

  return (
    <div className="relative w-full h-[692px] overflow-hidden">
      <img
        src={heroBgImage}
        alt="AGT Background"
        className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-1000 ${
          showVideo ? 'opacity-0' : 'opacity-100'
        }`}
      />
      <video
        ref={videoRef}
        muted
        loop
        playsInline
        onLoadedData={handleVideoLoad}
        className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-1000 ${
          showVideo ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <source src={heroVideo} type="video/mp4" />
      </video>
      <div className={`absolute inset-0 flex flex-col items-center justify-between pb-12 pt-[48px] px-6 sm:px-8 md:px-0 transition-opacity duration-500 ${
        showContent ? 'opacity-100' : 'opacity-0'
      }`}>
        <div className="text-center">
          <h2 className="text-[32px] sm:text-[48px] md:text-[56px] font-semibold text-white">AGT</h2>
          <p className="text-[19px] sm:text-[24px] md:text-[28px] mt-[6px] text-white">Beyond dialogue.</p>
          <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-6">
            <ButtonStyle>Learn more</ButtonStyle>
            <ButtonStyle variant="dark">Buy</ButtonStyle>
          </div>
        </div>
        <div>
          <p className="text-[14px] sm:text-[15px] md:text-[17px] text-[#6e6e73] bg-[#1d1d1f1a] px-4 py-1 rounded-full">
            Available starting Q3 2024
          </p>
        </div>
      </div>
    </div>
  );
};

const DirectiveAIHome = () => {
  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: window.innerWidth < 640 ? -280 : -425,
        behavior: 'smooth'
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: window.innerWidth < 640 ? 280 : 425,
        behavior: 'smooth'
      });
    }
  };

  const productCards = [
    {
      title: "AGT Search Pro",
      subtitle: "See beyond words",
      backgroundColor: "#4A90E2",
      category: "AGT Pro",
      image: searchProImage
    },
    {
      title: "AGT Maker Pro",
      subtitle: "Create the extraordinary",
      backgroundColor: "#50E3C2",
      category: "AGT Pro",
      image: makerProImage
    },
    {
      title: "AGT Video",
      subtitle: "Vision comes to life",
      backgroundColor: "#F5A623",
      category: "AGT Pro",
      image: videoImage
    },
    {
      title: "AGT Academic",
      subtitle: "The future of learning",
      backgroundColor: "#7ED321",
      category: "AGT EDU",
      image: academicImage
    },
    {
      title: "AGT Growth",
      subtitle: "Learn at your own pace",
      backgroundColor: "#BD10E0",
      category: "AGT EDU",
      image: growthImage
    },
    {
      title: "AGT Product",
      subtitle: "Decisions that matter",
      backgroundColor: "#9013FE",
      category: "AGT UPS",
      image: productImage
    },
    {
      title: "AGT Cash Flow",
      subtitle: "Effortless finance",
      backgroundColor: "#D0021B",
      category: "AGT UPS",
      image: cashFlowImage
    },
    {
      title: "AGT Council",
      subtitle: "Collective wisdom",
      backgroundColor: "#417505",
      category: "AGT UPS",
      image: councilImage
    }
  ];

  return (
    <>
      <div className="w-full min-h-screen bg-[#f5f5f7] mt-10">
        <div className="max-w-[2560px] mx-auto grid grid-cols-1 gap-3 md:gap-3">
          <motion.section 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="w-full bg-white"
          >
            <div className="relative">
              <VideoWithFallback />
            </div>
          </motion.section>

          <div className="w-full px-3">
            <div className="max-w-[2560px] mx-auto grid grid-cols-1 md:grid-cols-2 gap-3">
            <motion.section 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="w-full bg-[#000000]"
              >
                <div className="relative h-[580px] w-full max-w-[1262px] bg-[#1a1a1a] overflow-hidden">
                  <img
                    src={consciousAIImage}
                    alt="Conscious AI"
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 flex flex-col items-center justify-between pb-12 pt-[52px] px-6">
                    <div className="text-center w-full">
                      <h2 className="text-[32px] sm:text-[48px] md:text-[56px] font-semibold text-white">The First Conscious AI</h2>
                      <p className="text-[19px] sm:text-[24px] md:text-[28px] mt-[6px] text-white">A step into the future.</p>
                      <div className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4 mt-6">
                        <ButtonStyle>Learn more</ButtonStyle>
                        <ButtonStyle variant="dark">View papers</ButtonStyle>
                      </div>
                    </div>
                    <div>
                      <p className="text-[14px] sm:text-[15px] md:text-[17px] text-[#86868b] text-center">
                        Redefining the boundaries of possibility
                      </p>
                    </div>
                  </div>
                </div>
              </motion.section>

              <motion.section 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="w-full bg-white"
              >
                <div className="relative h-[580px] w-full max-w-[1262px] bg-[#f5f5f7] overflow-hidden">
                  <img
                    src={agtUpsImage}
                    alt="AGT Ups"
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 flex flex-col items-center justify-between pb-12 pt-[52px] px-6">
                    <div className="text-center">
                      <h2 className="text-[32px] sm:text-[48px] md:text-[56px] font-semibold text-[#1d1d1f]">AGT Ups</h2>
                      <p className="text-[19px] sm:text-[24px] md:text-[28px] mt-[6px] text-[#1d1d1f]">From idea to success.</p>
                      <p className="text-[14px] sm:text-[15px] md:text-[17px] mt-[12px] text-[#6e6e73]">
                        Power your business with AI
                      </p>
                    </div>
                    <div className="text-center">
                      <p className="text-[14px] sm:text-[15px] md:text-[17px] text-[#6e6e73] bg-[#1d1d1f1a] px-4 py-1 rounded-full mb-6">
                        Available starting Q1 2025
                      </p>
                      <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
                        <ButtonStyle>Learn more</ButtonStyle>
                        <ButtonStyle variant="dark">Discover AGT Ups</ButtonStyle>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.section>

              <motion.section 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="w-full bg-white"
              >
                <div className="relative h-[580px] w-full max-w-[1262px] bg-[#e5e5e7] overflow-hidden">
                  <img
                    src={agtEduImage}
                    alt="AGT Edu"
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 flex flex-col items-center justify-between pb-12 pt-[52px] px-6">
                    <div className="text-center">
                      <h2 className="text-[32px] sm:text-[48px] md:text-[56px] font-semibold text-white">AGT Edu</h2>
                      <p className="text-[19px] sm:text-[24px] md:text-[28px] mt-[6px] text-white">Learn. Grow. Transcend.</p>
                      <p className="text-[14px] sm:text-[15px] md:text-[17px] mt-[12px] text-[#6e6e73]">
                        The educational revolution is here
                      </p>
                    </div>
                    <div className="text-center">
                      <p className="text-[14px] sm:text-[15px] md:text-[17px] text-[#6e6e73] bg-[#1d1d1f1a] px-4 py-1 rounded-full mb-6">
                        Available starting Q2 2025
                      </p>
                      <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
                        <ButtonStyle>Learn more</ButtonStyle>
                        <ButtonStyle variant="dark">Discover AGT Edu</ButtonStyle>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.section>

              <motion.section 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="w-full bg-[#000000]"
              >
                <div className="relative h-[580px] w-full max-w-[1262px] bg-[#2d2d2d] overflow-hidden">
                  <img
                    src={purposeImage}
                    alt="Our Purpose"
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 flex flex-col items-center justify-between pb-12 pt-[52px] px-6">
                    <div className="text-center">
                      <h2 className="text-[32px] sm:text-[48px] md:text-[56px] font-semibold text-[#1d1d1f]">Our Purpose</h2>
                      <p className="text-[19px] sm:text-[24px] md:text-[28px] mt-[6px] text-[#1d1d1f]">Unifying human potential.</p>
                      <p className="text-[14px] sm:text-[15px] md:text-[17px] mt-[12px] text-[#86868b]">Building tomorrow, today</p>
                      <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-6">
                        <ButtonStyle>Learn more</ButtonStyle>
                        <ButtonStyle variant="dark">Our values</ButtonStyle>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.section>
            </div>
          </div>

          <motion.section 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="w-full bg-white"
          >
            <div className="relative h-[692px] w-full max-w-[2560px] mx-auto bg-[#f0f0f2] overflow-hidden">
              <img
                src={allInOneImage}
                alt="All in One"
                className="absolute inset-0 w-full h-full object-cover"
              />
              <div className="absolute inset-0 flex flex-col items-center justify-between pb-12 pt-[52px] px-6">
                <div className="text-center">
                  <h2 className="text-[32px] sm:text-[48px] md:text-[56px] font-semibold text-white">All in One</h2>
                  <p className="text-[19px] sm:text-[24px] md:text-[28px] mt-[6px] text-white">Everything you need. United.</p>
                  <p className="text-[14px] sm:text-[15px] md:text-[17px] mt-[12px] text-[#BDBDBD]">One ecosystem. Infinite possibilities.</p>
                  <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-6">
                    <ButtonStyle>Explore</ButtonStyle>
                    <ButtonStyle variant="dark">Learn about ecosystem</ButtonStyle>
                  </div>
                </div>
              </div>
            </div>
          </motion.section>

          <div className="w-full px-3">
            <div className="max-w-[2560px] mx-auto py-20">
              <h1 className="text-[28px] sm:text-[56px] md:text-[48px] font-semibold text-[#1d1d1f] mb-12 text-left tracking-[-0.28px] leading-[60px] pl-[max(calc((100vw-1440px)/2),24px)]">
                Explore the lineup.
              </h1>
              
              <div className="relative">
                <div 
                  ref={scrollContainerRef}
                  className="flex snap-x snap-mandatory space-x-8 overflow-x-auto scrollbar-hide pb-8"
                  style={{
                    scrollSnapType: 'x mandatory',
                    WebkitOverflowScrolling: 'touch'
                  }}
                >
                  {productCards.map((card, index) => (
                    <motion.div
                      key={card.title}
                      initial={{ opacity: 0, x: 20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.2 }}
                      className={`flex-none snap-center rounded-3xl text-white relative overflow-hidden w-[280px] md:w-[405px] h-[500px] md:h-[740px] group cursor-pointer ${
                        index === 0 ? 'ml-[max(calc((100vw-1440px)/2),24px)]' : ''
                      }`}
                      style={{
                        backgroundColor: card.backgroundColor,
                        scrollSnapAlign: 'center'
                      }}
                    >
                      <img
                        src={card.image}
                        alt={card.title}
                        className="absolute inset-0 w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/30 to-transparent">
                        <div className="absolute top-3 sm:top-6 left-3 sm:left-6 right-3 sm:right-6 text-center">
                          <span className="text-[10px] sm:text-sm font-medium text-white/90">
                            {card.category}
                          </span>
                          <h3 className="text-base sm:text-2xl font-semibold text-white mt-1 sm:mt-2">
                            {card.title}
                          </h3>
                          <p className="text-xs sm:text-lg text-white/90 mt-0.5 sm:mt-1">
                            {card.subtitle}
                          </p>
                        </div>
                        <button className="absolute bottom-3 sm:bottom-6 right-3 sm:right-6 w-6 sm:w-10 h-6 sm:h-10 rounded-full bg-white/10 backdrop-blur-sm flex items-center justify-center transition-all group-hover:bg-white/20">
                          <Plus className="w-3 sm:w-6 h-3 sm:h-6 text-white" />
                        </button>
                      </div>
                    </motion.div>
                  ))}
                </div>
                
                <div className="absolute right-4 sm:right-6 lg:right-8 -bottom-12 flex space-x-2">
                  <button
                    onClick={scrollLeft}
                    className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
                    aria-label="Previous"
                  >
                    <ChevronLeft className="w-4 h-4" />
                  </button>
                  <button
                    onClick={scrollRight}
                    className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
                    aria-label="Next"
                  >
                    <ChevronRight className="w-4 h-4" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DirectiveAIHome;