import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { MagnifyingGlassIcon, Bars3Icon, XMarkIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const location = useLocation();

  const quickLinks = [
    { name: 'AGT Pro', href: '/agt-pro' },
    { name: 'AGT Search Pro', href: '/search-pro' },
    { name: 'AGT Video', href: '/video' },
    { name: 'AGT Maker Pro', href: '/maker-pro' },
    { name: 'Integration Guide', href: '/integration' }
  ];

  const navigation = [
    { 
      name: 'AGT',
      href: '/agt',
      submenu: {
        'Discover AGT': {
          'Explore AGT': '/agt',
          'AGT Pro': '/agt-pro',
          'AGT Search Pro': '/search-pro',
          'AGT Maker Pro': '/maker-pro',
          'AGT Video': '/video',
        },
        'Get Started': {
          'Try AGT': '/try-agt',
          'Pricing': '/pricing',
          'Integration Guide': '/integration',
          'API Access': '/api-access',
        },
        'More for AGT': {
          'Support': '/support',
          'Documentation': '/docs',
          'Updates': '/updates',
          'Community': '/community',
        }
      }
    },
    {
      name: 'AGT Ups',
      href: '/ups',
      submenu: {
        'Explore AGT Ups': {
          'Overview': '/ups',
          'AGT Product': '/product',
          'AGT Cash Flow': '/cash-flow',
          'AGT Council': '/council',
        },
        'Start Using': {
          'Get Started': '/ups-start',
          'Pricing': '/ups-pricing',
          'Integration Guide': '/ups-integration',
          'Success Stories': '/success-stories',
        },
        'Resources': {
          'Support': '/ups-support',
          'Documentation': '/ups-docs',
          'Training': '/ups-training',
          'Community': '/ups-community',
        }
      }
    },
    {
      name: 'AGT Edu',
      href: '/edu',
      submenu: {
        'Explore Education': {
          'Overview': '/edu',
          'DirectiveAI Mode': '/directive-mode',
          'AGT Academic': '/academic',
          'AGT Growth': '/edu-growth',
        },
        'Get Started': {
          'Educational Access': '/edu-access',
          'Pricing': '/edu-pricing',
          'Implementation Guide': '/edu-guide',
          'Success Stories': '/edu-stories',
        },
        'Resources': {
          'Support': '/edu-support',
          'Training Materials': '/edu-materials',
          'Documentation': '/edu-docs',
          'Community': '/edu-community',
        }
      }
    },
    { 
      name: 'Research',
      href: '/research',
      submenu: {
        'Explore Research': {
          'Research Overview': '/research',
          'Latest Papers': '/papers',
          'Consciousness AI': '/consciousness-ai',
          'Innovation Lab': '/lab',
        },
        'Get Involved': {
          'Research Partnerships': '/partnerships',
          'Academic Access': '/academic-access',
          'Submit Paper': '/submit-paper',
          'Collaborate': '/collaborate',
        },
        'Resources': {
          'Ethics Guidelines': '/ethics',
          'Research Blog': '/blog',
          'Publications': '/publications',
          'Events': '/events',
        }
      }
    },
    { 
      name: 'Company',
      href: '/company',
      submenu: {
        'About Us': {
          'Overview': '/about',
          'Leadership': '/leadership',
          'Vision': '/vision',
          'Ethics': '/ethics',
        },
        'Connect': {
          'Careers': '/careers',
          'Press Room': '/press',
          'Events': '/events',
          'Contact': '/contact',
        },
        'Latest': {
          'News': '/news',
          'Blog': '/blog',
          'Research': '/research-updates',
          'Announcements': '/announcements',
        }
      }
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('nav') && !event.target.closest('.search-container')) {
        setActiveMenu(null);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  useEffect(() => {
    setActiveMenu(null);
    setIsMobileMenuOpen(false);
    setIsSearchOpen(false);
  }, [location]);

  const handleSearchClick = () => {
    setIsSearchOpen(!isSearchOpen);
    setActiveMenu(null);
  };

  const handleSearchClose = () => {
    setIsSearchOpen(false);
    setSearchQuery('');
    setSearchResults([]);
  };

  const MobileMenu = () => (
    <motion.div
      initial={{ opacity: 0, x: '-100%' }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: '-100%' }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      className="md:hidden bg-black fixed top-0 left-0 w-full h-full z-50 overflow-y-auto"
    >
      <div className="flex items-center justify-end px-8 py-4">
        <button
          onClick={() => setIsMobileMenuOpen(false)}
          className="text-gray-400 hover:text-white p-2"
        >
          <XMarkIcon className="w-6 h-6" />
        </button>
      </div>
      
      <div className="px-8">
        {navigation.map((item) => (
          <Link
            key={item.name}
            to={item.href}
            className="flex items-center justify-between py-4 text-xl text-gray-200 hover:text-white font-medium"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            <span>{item.name}</span>
            <ChevronRightIcon className="w-5 h-5 text-gray-400" />
          </Link>
        ))}
      </div>
    </motion.div>
  );

  const SearchResults = () => (
    <div className="max-w-2xl mx-auto w-full">
      {searchQuery ? (
        searchResults.length > 0 ? (
          <div className="space-y-5">
            {searchResults.map((result, index) => (
              <Link
                key={index}
                to={result.href}
                className="flex items-center text-gray-300 hover:text-white text-lg"
                onClick={handleSearchClose}
              >
                <ChevronRightIcon className="w-5 h-5 mr-3 text-gray-400" />
                <span>{result.name}</span>
              </Link>
            ))}
          </div>
        ) : (
          <p className="text-gray-400">No results found</p>
        )
      ) : (
        <>
          <h3 className="text-gray-400 text-base font-medium mb-4">Quick Links</h3>
          <div className="space-y-5">
            {quickLinks.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className="flex items-center text-gray-300 hover:text-white text-lg"
                onClick={handleSearchClose}
              >
                <ChevronRightIcon className="w-5 h-5 mr-3 text-gray-400" />
                <span>{item.name}</span>
              </Link>
            ))}
          </div>
        </>
      )}
    </div>
  );

  return (
    <>
      <motion.header
        className={`fixed top-0 w-full z-50 transition-all duration-300 ${
          isScrolled ? 'bg-black/80 backdrop-blur-md' : 'bg-black/95'
        }`}
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <nav className="mx-auto px-4">
          <div className="flex justify-between h-[44px] items-center">
            <button
              className="md:hidden text-gray-300 hover:text-white p-2"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              {isMobileMenuOpen ? (
                <XMarkIcon className="w-6 h-6" />
              ) : (
                <Bars3Icon className="w-6 h-6" />
              )}
            </button>

            <Link to="/" className="text-white font-medium text-xl">
              DirectiveAI
            </Link>
            
            <div className="hidden md:flex items-center space-x-9">
              {navigation.map((item) => (
                <div
                  key={item.name}
                  className="relative"
                  onMouseEnter={() => setActiveMenu(item.name)}
                >
                  <Link
                    to={item.href}
                    className={`text-[12px] font-light tracking-wide transition-colors duration-200 hover:text-white ${
                      location.pathname === item.href || activeMenu === item.name
                        ? 'text-white'
                        : 'text-gray-300'
                    }`}
                  >
                    {item.name}
                  </Link>
                </div>
              ))}
            </div>

            <button
              className="text-gray-400 hover:text-white p-2"
              onClick={handleSearchClick}
            >
              <MagnifyingGlassIcon className="w-5 h-5" />
            </button>
          </div>
        </nav>

        <AnimatePresence>
          {isMobileMenuOpen && <MobileMenu />}
        </AnimatePresence>

        <AnimatePresence>
          {activeMenu && !isMobileMenuOpen && !isSearchOpen && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
              className="absolute top-[44px] left-0 w-full bg-black/80 backdrop-blur-md z-40"
              onMouseLeave={() => setActiveMenu(null)}
            >
              <div className="max-w-7xl mx-auto py-8 px-4">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-x-6 gap-y-4">
                  {Object.entries(navigation.find(item => item.name === activeMenu)?.submenu || {}).map(([category, links], columnIndex) => (
                    <div key={category} className="space-y-4">
                      <h3 className="text-gray-400 font-medium text-sm mb-4">{category}</h3>
                      <div className="space-y-3">
                        {Object.entries(links).map(([label, href]) => (
                          <Link
                            key={label}
                            to={href}
                            className={`block ${
                              columnIndex === 0 ? 'text-lg' : columnIndex === 1 ? 'text-base' : 'text-sm'
                            } text-gray-300 hover:text-white font-medium leading-tight tracking-normal transition-colors duration-200`}
                          >
                            {label}
                          </Link>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {isSearchOpen && (
            <motion.div 
              className="absolute top-[44px] left-0 w-full bg-black/80 backdrop-blur-md z-40"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
              onMouseLeave={() => {
                if (!isMobile) {
                  handleSearchClose();
                }
              }}
            >
              <div className="max-w-7xl mx-auto py-8 px-4">
                <div className="flex items-center justify-between max-w-2xl mx-auto mb-6">
                  <div className="flex items-center flex-1">
                    <MagnifyingGlassIcon className="w-5 h-5 text-gray-400 mr-3" />
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => {
                        const query = e.target.value;
                        setSearchQuery(query);
                        
                        if (query.trim()) {
                          const filteredResults = navigation.flatMap(section => {
                            const menuItems = Object.values(section.submenu).flatMap(submenu => 
                              Object.entries(submenu).map(([name, href]) => ({ name, href }))
                            );
                            return menuItems;
                          }).filter(item => 
                            item.name.toLowerCase().includes(query.toLowerCase())
                          );
                          setSearchResults(filteredResults.slice(0, 5));
                        } else {
                          setSearchResults([]);
                        }
                      }}
                      placeholder="Search directiveai.com"
                      className="bg-transparent text-gray-400 text-lg focus:outline-none w-full"
                      autoFocus
                    />
                  </div>
                  <button
                    onClick={handleSearchClose}
                    className="text-gray-400 hover:text-white p-2"
                  >
                    <XMarkIcon className="w-6 h-6" />
                  </button>
                </div>
                
                <SearchResults />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.header>

      <AnimatePresence>
        {isMobile && isSearchOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/40 backdrop-blur-sm z-40"
            onClick={handleSearchClose}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Header;