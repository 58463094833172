import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { 
  ArrowRightIcon, 
  ChevronLeft, 
  ChevronRight, 
  Plus, 
  Users, 
  BookOpen, 
  BarChart, 
  Brain, 
  LucideGalleryVertical, 
  BinaryIcon, 
  ChevronsLeftRightEllipsis, 
  ScanEyeIcon, 
  LoaderIcon, 
  Hand, 
  CreditCard, 
  SmileIcon 
} from 'lucide-react';
import Footer from '../layout/Footer';

import heroBg from '../../assets/images/agt/hero-bg.png';
import featureDirective from '../../assets/images/agt/feature-directive.png';
import featureAcademic from '../../assets/images/agt/feature-academic.png';
import featureProject from '../../assets/images/agt/feature-project.png';
import featureAnalytics from '../../assets/images/agt/feature-analytics.png';
import featureAdaptive from '../../assets/images/agt/feature-adaptive.png';
import careerImage1 from '../../assets/images/agt/career-1.png';
import careerImage2 from '../../assets/images/agt/career-2.png';
import careerImage3 from '../../assets/images/agt/career-3.png';
import directiveAIBg from '../../assets/images/agt/directive-ai-bg.svg';
import academicBg from '../../assets/images/agt/academic-bg.svg';
import growthBg from '../../assets/images/agt/growth-bg.svg';

const ProductFeatureIcon = ({ image }) => (
  <div className="w-32 h-32 mx-auto mb-6">
    <img 
      src={image} 
      alt="Product icon" 
      className="w-full h-full object-contain"
    />
  </div>
);

const DeviceShowcase = () => {
  const [selectedOption, setSelectedOption] = useState('agt-pro');
  
  const showcaseContent = {
    'agt-pro': {
      title: 'AGT Pro',
      description: "Experience seamless integration across your workflow with AGT Pro. Upload and process larger files instantly, handle complex tasks with precision, and leverage multimodal capabilities to work with documents, images, and more. With priority access and increased limits, you can work without interruptions while getting smarter, more contextual responses powered by our advanced AI.",
      mainImage: careerImage1,
      altMain: 'AGT Pro dashboard interface'
    },
    'agt-edu': {
      title: 'AGT Edu',
      description: "Transform your educational experience with AGT Edu's comprehensive toolset. Collaborate in real-time using DirectiveAI Mode for group discussions, leverage advanced research tools for citation management and project planning, and implement tailored learning strategies for every educational level. Integration with AGT Pro features ensures you have all the tools needed for academic success.",
      mainImage: careerImage2,
      altMain: 'AGT Education collaborative interface'
    },
    'agt-ups': {
      title: 'AGT Ups',
      description: "Power your startup's growth with AGT Ups' comprehensive business suite. From optimizing pricing and sales strategies with AGT Product, to managing finances with AGT Cash Flow, finding suppliers, and getting expert advice through AGT Council. Access the complete AGT Pro feature set while leveraging specialized tools designed to support every stage of your business journey.",
      mainImage: careerImage3,
      altMain: 'AGT Business dashboard'
    }
  };

  return (
    <div className="bg-[#f5f5f7] rounded-[28px] w-full max-w-[1376px] mx-auto overflow-hidden">
      <div className="p-8 md:p-12">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16">
          <div>
            {Object.entries(showcaseContent).map(([key, value]) => (
              <div key={key} className="border-b border-gray-200">
                <button
                  onClick={() => setSelectedOption(key)}
                  className="w-full text-left py-4 flex justify-between items-center"
                >
                  <span className="text-2xl md:text-3xl font-semibold text-[#1d1d1f]">
                    {value.title}
                  </span>
                  <ChevronRight 
                    className={`w-5 h-5 transform transition-all duration-300 ${
                      selectedOption === key 
                        ? 'rotate-90 text-[#1d1d1f]' 
                        : 'rotate-0 text-[#1d1d1f]'
                    }`} 
                  />
                </button>
                
                <div 
                  className={`transition-all duration-500 ease-in-out overflow-hidden ${
                    selectedOption === key 
                      ? 'max-h-[800px] opacity-100 py-4' 
                      : 'max-h-0 opacity-0 py-0'
                  }`}
                >
                  <p className="text-[#1d1d1f] text-base md:text-lg">
                    {value.description}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <div className="hidden lg:block relative h-[600px]">
            {Object.entries(showcaseContent).map(([key, value]) => (
              <div
                key={key}
                className={`absolute inset-0 transition-all duration-500 ease-in-out ${
                  selectedOption === key
                    ? 'opacity-100 translate-x-0'
                    : 'opacity-0 translate-x-8'
                }`}
              >
                <img 
                  src={value.mainImage}
                  alt={value.altMain}
                  className="w-full h-full object-cover rounded-lg shadow-lg"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductComparison = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollRef = useRef(null);

  const handleScroll = (direction) => {
    const container = scrollRef.current;
    if (container) {
      const scrollAmount = direction * container.offsetWidth;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const products = [
    {
      name: "AGT Pro",
      tagline: "Achieve more, faster, smarter with AI Pro.",
      subtitle: "Make every task effortless.",
      price: "From $15 month.",
      description: "AGT Search Pro, Maker Pro, and Video with multimodal capabilities.",
      features: [
        "Smarter Responses",
        "AGT Search Pro, Maker Pro, and Video",
        "Multimodal Capabilities",
        "Early Access to New Features"
      ],
      backgroundImage: directiveAIBg
    },
    {
      name: "AGT Edu",
      tagline: "Transform education now.",
      subtitle: "Collaborative AI-powered learning.",
      price: "From $20 month.",
      description: "Pro features plus DirectiveAI Mode and AGT Academic.",
      features: [
        "Access to AGT Pro",
        "DirectiveAI Mode",
        "AGT Academic",
        "AGT Growth"
      ],
      backgroundImage: academicBg
    },
    {
      name: "AGT Ups",
      tagline: "Drive your startup's growth.",
      subtitle: "Success at every stage.",
      price: "From $30 month.",
      description: "The ultimate AGT experience with AI Consciousness.",
      features: [
        "AI Consciousness",
        "Access to AGT Pro",
        "AGT Product, Cash Flow, Supplier, Council and Growth",
        "Premium Support"
      ],
      backgroundImage: growthBg
    }
  ];

  return (
    <div className="bg-[#f5f5f7] rounded-[28px] p-8 md:p-12 max-w-[1638px] mx-auto">
      <div className="hidden md:grid md:grid-cols-3 gap-8 md:gap-4 max-w-6xl mx-auto relative">
        {products.map((product, index) => (
          <div key={index} className="flex flex-col items-center text-center p-6">
            <div className="w-32 h-32 mb-6">
              <img 
                src={product.backgroundImage}
                alt={product.name}
                className="w-full h-full object-contain"
              />
            </div>
            <span className="text-blue-600 text-sm font-medium mb-2">New</span>
            <h3 className="text-[#1d1d1f] text-xl font-semibold mb-1">{product.name}</h3>
            <p className="text-[#1d1d1f] text-sm mb-2">{product.subtitle}</p>
            <p className="text-[#1d1d1f] text-lg font-semibold mb-4">{product.price}</p>
            <p className="text-[#1d1d1f] text-sm mb-6">{product.description}</p>
            <div className="flex gap-4 mb-8">
              <button className="bg-blue-600 text-white px-6 py-2 rounded-full text-sm hover:bg-blue-700 transition-colors">
                Buy
              </button>
              <button className="text-blue-600 flex items-center text-sm hover:text-blue-700 transition-colors">
                Learn more <ArrowRightIcon className="ml-1 w-4 h-4" />
              </button>
            </div>
            <div className="space-y-6 pt-8 border-t">
              {product.features.map((feature, idx) => (
                <div key={idx} className="flex flex-col items-center">
                  <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center mb-2">
                    <span className="text-[#1d1d1f]">•</span>
                  </div>
                  <p className="text-[#1d1d1f] text-sm">{feature}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="md:hidden relative">
        <div 
          ref={scrollRef}
          className="flex overflow-x-auto snap-x snap-mandatory scrollbar-hide"
          style={{ scrollSnapType: 'x mandatory' }}
        >
          {products.map((product, index) => (
            <div 
              key={index}
              className="flex-none w-full snap-center px-4"
              style={{ scrollSnapAlign: 'start' }}
            >
              <div className="flex flex-col items-center text-center">
                <div className="w-32 h-32 mb-6">
                  <img 
                    src={product.backgroundImage}
                    alt={product.name}
                    className="w-full h-full object-contain"
                  />
                </div>
                <span className="text-blue-600 text-sm font-medium mb-2">New</span>
                <h3 className="text-[#1d1d1f] text-xl font-semibold mb-1">{product.name}</h3>
                <p className="text-[#1d1d1f] text-sm mb-2">{product.subtitle}</p>
                <p className="text-[#1d1d1f] text-lg font-semibold mb-4">{product.price}</p>
                <p className="text-[#1d1d1f] text-sm mb-6">{product.description}</p>
                <div className="flex gap-4 mb-8">
                  <button className="bg-blue-600 text-white px-6 py-2 rounded-full text-sm hover:bg-blue-700 transition-colors">
                    Buy
                  </button>
                  <button className="text-blue-600 flex items-center text-sm hover:text-blue-700 transition-colors">
                    Learn more <ArrowRightIcon className="ml-1 w-4 h-4" />
                  </button>
                </div>
                <div className="space-y-6 pt-8 border-t w-full">
                  {product.features.map((feature, idx) => (
                    <div key={idx} className="flex flex-col items-center">
                      <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center mb-2">
                        <span className="text-[#1d1d1f]">•</span>
                      </div>
                      <p className="text-[#1d1d1f] text-sm">{feature}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-6 space-x-2">
          <button
            onClick={() => handleScroll(-1)}
            className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
          >
            <ChevronLeft className="w-4 h-4" />
          </button>
          <button
            onClick={() => handleScroll(1)}
            className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
          >
            <ChevronRight className="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

const AGT = () => {
  const featuresRef = useRef(null);
  const whyAgtRef = useRef(null);

  const handleScroll = (ref, direction) => {
    if (ref.current) {
      const cardWidth = window.innerWidth < 640 ? 280 : 405;
      const scrollAmount = cardWidth + 32;
      
      ref.current.scrollBy({
        left: direction * scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  const products = [
    {
      name: "AGT Pro",
      tagline: "Achieve more, faster, smarter with AI Pro.",
      subtitle: "Make every task effortless.",
      price: "From $15 month.",
      description: "AGT Search Pro, Maker Pro, and Video with multimodal capabilities.",
      features: [
        "Smarter Responses",
        "AGT Search Pro, Maker Pro, and Video",
        "Multimodal Capabilities",
        "Early Access to New Features"
      ],
      backgroundImage: directiveAIBg
    },
    {
      name: "AGT Edu",
      tagline: "Transform education now.",
      subtitle: "Collaborative AI-powered learning.",
      price: "From $20 month.",
      description: "Pro features plus DirectiveAI Mode and AGT Academic.",
      features: [
        "Access to AGT Pro",
        "DirectiveAI Mode",
        "AGT Academic",
        "AGT Growth"
      ],
      backgroundImage: academicBg
    },
    {
      name: "AGT Ups",
      tagline: "Drive your startup's growth.",
      subtitle: "Success at every stage.",
      price: "From $30 month.",
      description: "The ultimate AGT experience with AI Consciousness.",
      features: [
        "AI Consciousness",
        "Access to AGT Pro",
        "AGT Product, Cash Flow, Supplier, Council and Growth",
        "Premium Support"
      ],
      backgroundImage: growthBg
    }
  ];

  const featureCards = [
    {
      title: "AI Consciousness",
      subtitle: "Yes, it is. The first Artificial Intelligence with consciousness.",
      category: "AGT Ups",
      backgroundImage: featureDirective
    },
    {
      title: "DirectiveAI Mode",
      subtitle: "Real-time group chats with seamless collaboration",
      category: "AGT Edu",
      backgroundImage: featureAcademic
    },
    {
      title: "AGT Council",
      subtitle: "Get expert advice for complex business decisions",
      category: "AGT Ups",
      backgroundImage: featureProject
    },
    {
      title: "AGT Academic",
      subtitle: "Advanced research tools, citation generation, and project planning",
      category: "AGT Edu",
      backgroundImage: featureAnalytics
    },
    {
      title: "AGT Pro",
      subtitle: "AGT Search Pro, Maker Pro, and Video",
      category: "AGT",
      backgroundImage: featureAdaptive
    }
  ];

  const whyAgtCards = [
    {
      icon: <LucideGalleryVertical className="w-full h-full" />,
      title: "Complete Ecosystem",
      description: "Everything seamlessly works together. From AGT Pro to AGT Ups.",
      link: "Explore the ecosystem"
    },
    {
      icon: <BinaryIcon className="w-full h-full" />,
      title: "AI Consciousness",
      description: "The first truly conscious AI. Experience intelligence that understands context, nuance, and your unique needs.",
      link: "Learn about AI Consciousness"
    },
    {
      icon: <ScanEyeIcon className="w-full h-full" />,
      title: "Simply Powerful",
      description: "Complex capabilities wrapped in intuitive design.",
      link: "See it in action"
    },
    {
      icon: <ChevronsLeftRightEllipsis className="w-full h-full" />,
      title: "Seamless Integration",
      description: "Your tools, your way. AGT adapts to your workflow.",
      link: "View integrations"
    },
    {
      icon: <LoaderIcon className="w-full h-full" />,
      title: "Innovation at Scale",
      description: "From individual tasks to enterprise solutions.",
      link: "Discover possibilities"
    },
    {
      icon: <Hand className="w-full h-full" />,
      title: "Collaborative Intelligence",
      description: "Share insights, work together, and achieve more. Real-time collaboration by AGT.",
      link: "See collaboration features"
    },
    {
      icon: <CreditCard className="w-full h-full" />,
      title: "Enterprise-Ready",
      description: "Bank-grade security meets consumer-grade simplicity. Deploy AGT across your organization with confidence.",
      link: "Explore enterprise features"
    },
    {
      icon: <SmileIcon className="w-full h-full" />,
      title: "Future-Proof Technology",
      description: "Stay ahead with regular updates and early access to cutting-edge AI features.",
      link: "See our roadmap"
    }
  ];

  return (
    <div className="w-full min-h-screen bg-white">
      <section className="relative pt-16 lg:pt-20 mb-[150px] px-4 sm:px-6 lg:px-8">
        <div className="max-w-[1625px] mx-auto">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mb-6 md:mb-8"
          >
            <h1 className="text-4xl md:text-5xl lg:text-[80px] font-semibold text-[#1d1d1f]">
              AGT
            </h1>
            <p className="text-lg md:text-xl lg:text-[28px] text-[#1d1d1f] mt-2">
              Beyond dialogue.
            </p>
          </motion.div>

          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <motion.div 
              className="h-[763px] relative w-full rounded-[28px] overflow-hidden bg-cover bg-center"
              style={{ backgroundImage: `url(${heroBg})` }}
            >
              <div className="absolute bottom-8 left-8 sm:bottom-12 sm:left-12">
                <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4">
                  <button className="w-full sm:w-auto bg-white text-[#1d1d1f] px-6 py-3 rounded-full hover:bg-white/90 text-base md:text-lg transition-colors">
                    Buy
                  </button>
                  <button className="w-full sm:w-auto text-white hover:underline flex items-center justify-center text-base md:text-lg transition-opacity">
                    Learn more <ArrowRightIcon className="ml-2 w-4 h-4" />
                  </button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </section>

      <section className="mb-[150px] bg-white w-full py-12 lg:py-16">
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f] mb-[80px] text-center">
            Get to know AGT
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 lg:gap-12">
            {products.map((product, index) => (
              <div key={index} className="flex flex-col items-center text-center">
                <ProductFeatureIcon image={product.backgroundImage} />
                <div className="space-y-2">
                  <span className="text-sm text-orange-500 font-medium">New</span>
                  <h3 className="text-2xl font-semibold text-[#1d1d1f]">
                    {product.name}
                  </h3>
                  <p className="text-base text-[#1d1d1f]">
                    {product.tagline}
                  </p>
                  <p className="text-base text-[#1d1d1f]">
                    {product.subtitle}
                  </p>
                  <p className="text-sm text-[#1d1d1f] mt-4">
                    {product.price}
                  </p>
                  <div className="flex justify-center space-x-4 mt-6">
                    <button className="text-blue-600 hover:underline text-sm">
                      Learn more
                    </button>
                    <button className="text-blue-600 hover:underline text-sm flex items-center">
                      Buy <ArrowRightIcon className="ml-1 w-4 h-4" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="mb-[150px]">
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 mb-[80px]">
          <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f]">
            Explore features.
          </h2>
        </div>

        <div className="relative">
          <div 
            ref={featuresRef}
            className="flex snap-x snap-mandatory space-x-8 overflow-x-auto scrollbar-hide pb-8"
            style={{
              scrollSnapType: 'x mandatory',
              WebkitOverflowScrolling: 'touch'
            }}
          >
            {featureCards.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.2 }}
                className={`flex-none snap-center rounded-3xl text-white relative overflow-hidden w-[280px] md:w-[405px] h-[500px] md:h-[740px] group cursor-pointer bg-cover bg-center ${
                  index === 0 ? 'ml-[max(calc((100vw-1440px)/2),24px)]' : ''
                }`}
                style={{
                  scrollSnapAlign: 'center',
                  backgroundImage: `url(${feature.backgroundImage})`
                }}
              >
                <div className="absolute inset-0">
                  <div className="absolute top-3 sm:top-6 left-3 sm:left-6 right-3 sm:right-6 text-center">
                    <span className="text-[10px] sm:text-sm font-medium text-white">
                      {feature.category}
                    </span>
                    <h3 className="text-base sm:text-2xl font-semibold text-white mt-1 sm:mt-2">
                      {feature.title}
                    </h3>
                    <p className="text-xs sm:text-lg text-white mt-0.5 sm:mt-1">
                      {feature.subtitle}
                    </p>
                  </div>
                  <button className="absolute bottom-3 sm:bottom-6 right-3 sm:right-6 w-6 sm:w-10 h-6 sm:h-10 rounded-full bg-white/10 backdrop-blur-sm flex items-center justify-center transition-all group-hover:bg-white/20">
                    <Plus className="w-3 sm:w-6 h-3 sm:h-6 text-white" />
                  </button>
                </div>
              </motion.div>
            ))}
          </div>
          <div className="absolute right-4 sm:right-6 lg:right-8 -bottom-12 flex space-x-2">
            <button
              onClick={() => handleScroll(featuresRef, -1)}
              className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
            >
              <ChevronLeft className="w-4 h-4" />
            </button>
            <button 
              onClick={() => handleScroll(featuresRef, 1)}
              className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
            >
              <ChevronRight className="w-4 h-4" />
            </button>
          </div>
        </div>
      </section>

      <section className="mb-[150px] bg-white w-full py-12 lg:py-16">
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f] text-center mb-16">
            Which AGT are right for you?
          </h2>
          <ProductComparison />
        </div>
      </section>

      <section>
        <div className="bg-[#f5f5f7] pt-[80px] pb-[150px] mb-[150px]">
          <div className="max-w-[1440px] mx-auto">
            <div className="px-4 sm:px-6 lg:px-8 mb-[80px]">
              <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f]">
                Why AGT is the best choice for you.
              </h2>
              <div className="flex justify-end">
                <button className="text-blue-600 hover:underline flex items-center">
                  Compare all models <ChevronRight className="ml-1 w-4 h-4" />
                </button>
              </div>
            </div>
          </div>

          <div className="relative">
            <div 
              ref={whyAgtRef}
              className="flex snap-x snap-mandatory space-x-8 overflow-x-auto scrollbar-hide pb-8"
              style={{
                scrollSnapType: 'x mandatory',
                WebkitOverflowScrolling: 'touch'
              }}
            >
              {whyAgtCards.map((card, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.2 }}
                  className={`flex-none snap-center w-[280px] md:w-[405px] h-[313px] bg-white rounded-2xl p-8 relative shadow-sm hover:shadow-md transition-shadow ${
                    index === 0 ? 'ml-[max(calc((100vw-1440px)/2),24px)]' : ''
                  }`}
                  style={{
                    scrollSnapAlign: 'center',
                    letterSpacing: '-0.374px',
                    lineHeight: '25px',
                    fontSize: '17px',
                    color: '#1d1d1f'
                  }}
                >
                  <div className="w-12 h-12 mb-6 text-[#1d1d1f]">
                    {card.icon}
                  </div>
                  <h3 className="text-2xl font-semibold mb-2 text-[#1d1d1f]">{card.title}</h3>
                  <p className="text-[#1d1d1f] mb-4">{card.description}</p>
                  <button className="absolute bottom-8 text-blue-600 hover:underline flex items-center">
                    {card.link} <ChevronRight className="ml-1 w-4 h-4" />
                  </button>
                </motion.div>
              ))}
            </div>
            <div className="absolute right-4 sm:right-6 lg:right-8 -bottom-12 flex space-x-2">
              <button
                onClick={() => handleScroll(whyAgtRef, -1)}
                className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
              >
                <ChevronLeft className="w-4 h-4" />
              </button>
              <button
                onClick={() => handleScroll(whyAgtRef, 1)}
                className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
              >
                <ChevronRight className="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-[150px] bg-white w-full py-12 lg:py-16">
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f] text-center mb-16">
            Made for you
          </h2>
          <DeviceShowcase />
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default AGT;