import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { 
  ArrowRightIcon, 
  ChevronLeft, 
  ChevronRight, 
  Plus, 
  Users, 
  BookOpen, 
  BarChart, 
  Brain, 
  LucideGalleryVertical, 
  Hand, 
  CreditCard, 
  SmileIcon 
} from 'lucide-react';
import Footer from '../../layout/Footer';

import heroBg from '../../../assets/images/ups/hero-bg.png';
import featureDirective from '../../../assets/images/ups/feature-directive.png';
import featureAcademic from '../../../assets/images/ups/feature-academic.png';
import featureProject from '../../../assets/images/ups/feature-project.png';
import featureAnalytics from '../../../assets/images/ups/feature-analytics.png';
import featureAdaptive from '../../../assets/images/ups/feature-adaptive.png';
import careerImage1 from '../../../assets/images/ups/career-1.png';
import careerImage2 from '../../../assets/images/ups/career-2.png';
import careerImage3 from '../../../assets/images/ups/career-3.png';
import careerImage4 from '../../../assets/images/ups/career-4.png';
import careerImage5 from '../../../assets/images/ups/career-5.png';
import directiveAIBg from '../../../assets/images/ups/directive-ai-bg.svg';
import academicBg from '../../../assets/images/ups/academic-bg.svg';
import growthBg from '../../../assets/images/ups/growth-bg.svg';
import upsStartups from '../../../assets/images/ups/ups-startups.png';
import upsIndustries from '../../../assets/images/ups/ups-industries.png';

import CashFlowSvg from '../../../assets/images/ups/Cash-flow.svg';
import CouncilSvg from '../../../assets/images/ups/Council.svg';
import GrowthSvg from '../../../assets/images/ups/Growth.svg';
import ProductSvg from '../../../assets/images/ups/Product.svg';
import SupplierSvg from '../../../assets/images/ups/Supplier.svg';

const UpsEssentials = () => (
  <section className="mb-[150px] bg-white w-full py-12 lg:py-16">
    <div className="max-w-[1680px] mx-auto px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f] text-center mb-16">
        Ups essentials
      </h2>
      <div className="relative w-[1680px] h-[673px] mx-auto flex justify-between">
        <div className="relative w-[830px] h-[673px] rounded-[28px] overflow-hidden">
          <img 
            src={upsStartups} 
            alt="Business stages visualization" 
            className="absolute inset-0 w-full h-full object-cover"
          />
          <div className="absolute inset-0">
            <div className="p-8 md:p-12">
              <h3 className="text-2xl md:text-3xl font-semibold text-white mb-4">For every stage</h3>
              <p className="text-base md:text-lg text-white">
                From innovative startups to industry giants, AGT Ups scales with your business. Whether you're at the idea stage or leading a market player, our tools adapt to your growth journey.
              </p>
            </div>
          </div>
        </div>
        
        <div className="relative w-[830px] h-[673px] rounded-[28px] overflow-hidden">
          <img 
            src={upsIndustries} 
            alt="Industry types visualization" 
            className="absolute inset-0 w-full h-full object-cover"
          />
          <div className="absolute inset-0">
            <div className="p-8 md:p-12">
              <h3 className="text-2xl md:text-3xl font-semibold text-white mb-4">For every industry</h3>
              <p className="text-base md:text-lg text-white">
                AGT Ups provides versatile solutions that work across all sectors. From tech to manufacturing, retail to services, our platform adapts to your industry's unique needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const DeviceShowcase = () => {
  const [selectedOption, setSelectedOption] = useState('product');
  
  const showcaseContent = {
    'product': {
      title: 'AGT Product',
      description: "Optimize pricing and sales strategies with AI-powered market insights. Perfect your market fit and boost revenue through data-driven decisions.",
      image: careerImage1,
      alt: 'Product interface'
    },
    'cashflow': {
      title: 'AGT Cash Flow',
      description: "Streamline financial operations with instant receipt processing and automated updates. Get real-time insights into your business finances.",
      image: careerImage2,
      alt: 'Cash Flow dashboard'
    },
    'supplier': {
      title: 'AGT Supplier',
      description: "Find and negotiate with suppliers through AI-powered matching. Access a network of pre-vetted suppliers and optimize your supply chain.",
      image: careerImage3,
      alt: 'Supplier Finder interface'
    },
    'council': {
      title: 'AGT Council',
      description: "Get expert advice for complex business decisions backed by AI analysis. Access strategic guidance when you need it most.",
      image: careerImage4,
      alt: 'Council dashboard'
    },
    'growth': {
      title: 'AGT Growth',
      description: "Comprehensive strategies tailored to every stage of your startup's journey. From inception to scale, get data-driven insights for sustainable growth.",
      image: careerImage5,
      alt: 'Growth dashboard'
    }
  };

  return (
    <div className="bg-[#f5f5f7] rounded-[28px] w-full max-w-[1680px] mx-auto overflow-hidden">
      <div className="p-8 md:p-12">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16">
          <div>
            {Object.entries(showcaseContent).map(([key, value]) => (
              <div key={key} className="border-b border-gray-200">
                <button
                  onClick={() => setSelectedOption(key)}
                  className="w-full text-left py-4 flex justify-between items-center"
                >
                  <span className="text-2xl md:text-3xl font-semibold text-[#1d1d1f]">
                    {value.title}
                  </span>
                  <ChevronRight 
                    className={`w-5 h-5 transform transition-all duration-300 ${
                      selectedOption === key 
                        ? 'rotate-90 text-[#1d1d1f]' 
                        : 'rotate-0 text-[#1d1d1f]'
                    }`} 
                  />
                </button>
                
                <div 
                  className={`transition-all duration-500 ease-in-out overflow-hidden ${
                    selectedOption === key 
                      ? 'max-h-[800px] opacity-100 py-4' 
                      : 'max-h-0 opacity-0 py-0'
                  }`}
                >
                  <p className="text-[#1d1d1f] text-base md:text-lg">
                    {value.description}
                  </p>
                  
                  <div className="lg:hidden mt-6">
                    <div className="relative">
                      <div 
                        className="w-full h-64 rounded-lg shadow-lg bg-cover bg-center"
                        style={{ backgroundImage: `url(${value.image})` }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="hidden lg:block relative h-[600px]">
            {Object.entries(showcaseContent).map(([key, value]) => (
              <div
                key={key}
                className={`absolute inset-0 transition-all duration-500 ease-in-out ${
                  selectedOption === key
                    ? 'opacity-100 translate-x-0'
                    : 'opacity-0 translate-x-8'
                }`}
              >
                <div 
                  className="w-full h-full rounded-lg shadow-lg bg-cover bg-center"
                  style={{ backgroundImage: `url(${value.image})` }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const ServiceCard = ({ service }) => (
  <div className="flex flex-col items-center text-center">
    <div className="w-32 h-32 mb-6">
      <img 
        src={service.icon} 
        alt={service.title} 
        className="w-full h-full object-contain"
      />
    </div>
    <div className="mt-6 space-y-2">
      <span className="text-sm text-blue-600 font-medium">Featured</span>
      <h3 className="text-2xl font-semibold text-[#1d1d1f]">{service.title}</h3>
      <p className="text-base text-[#1d1d1f]">{service.description1}</p>
      <p className="text-base text-[#1d1d1f]">{service.description2}</p>
      <div className="flex justify-center space-x-4 mt-6">
        <button className="text-blue-600 hover:underline text-sm">Learn more</button>
        <button className="text-blue-600 hover:underline text-sm flex items-center">
          Try now <ArrowRightIcon className="ml-1 w-4 h-4" />
        </button>
      </div>
    </div>
  </div>
);

const ProductComparison = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollRef = useRef(null);

  const handleScroll = (direction) => {
    const container = scrollRef.current;
    if (container) {
      const scrollAmount = direction * container.offsetWidth;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const products = [
    {
      name: "AGT Product",
      tagline: "Market Optimization",
      subtitle: "Transform your product strategy",
      description: "AI-powered insights for perfect market fit",
      features: [
        "Dynamic pricing optimization",
        "Market trend analysis",
        "Competitor tracking",
        "Sales strategy recommendations"
      ],
      icon: ProductSvg
    },
    {
      name: "AGT Cash Flow",
      tagline: "Financial Intelligence",
      subtitle: "Streamline your operations",
      description: "Automate financial processes instantly",
      features: [
        "Instant receipt processing",
        "Automated categorization",
        "Cash flow forecasting",
        "Financial report automation"
      ],
      icon: CashFlowSvg
    },
    {
      name: "AGT Supplier",
      tagline: "Supply Chain Intelligence",
      subtitle: "Optimize your supplier network",
      description: "AI-powered supplier matching and negotiation",
      features: [
        "Supplier matching",
        "Negotiation assistance",
        "Risk assessment",
        "Contract management"
      ],
      icon: SupplierSvg
    },
    {
      name: "AGT Council",
      tagline: "Expert Guidance",
      subtitle: "Strategic business advice",
      description: "AI-enhanced expert guidance for decisions",
      features: [
        "Strategic planning",
        "Risk analysis",
        "Decision support",
        "Expert consultation"
      ],
      icon: CouncilSvg
    },
    {
      name: "AGT Growth",
      tagline: "Growth Engine",
      subtitle: "Scale your startup",
      description: "Data-driven growth strategies",
      features: [
        "Growth strategy planning",
        "Performance tracking",
        "Milestone management",
        "Scale-up roadmap"
      ],
      icon: GrowthSvg
    }
  ];

  return (
    <div className="bg-[#f5f5f7] rounded-[28px] p-8 md:p-12 max-w-[1638px] mx-auto">
      <div className="hidden md:grid md:grid-cols-5 gap-8 md:gap-4 max-w-6xl mx-auto relative">
        {products.map((product, index) => (
          <div key={index} className="flex flex-col items-center text-center p-6">
            <div className="w-32 h-32 mb-6">
              <img 
                src={product.icon}
                alt={product.name}
                className="w-full h-full object-contain"
              />
            </div>
            <span className="text-blue-600 text-sm font-medium mb-2">Included in AGT Ups</span>
            <h3 className="text-[#1d1d1f] text-xl font-semibold mb-1">{product.name}</h3>
            <p className="text-[#1d1d1f] text-sm mb-2">{product.subtitle}</p>
            <p className="text-[#1d1d1f] text-sm mb-6">{product.description}</p>
            <button className="text-blue-600 flex items-center text-sm hover:text-blue-700 transition-colors">
              Learn more <ArrowRightIcon className="ml-1 w-4 h-4" />
            </button>
            <div className="space-y-6 pt-8 border-t">
              {product.features.map((feature, idx) => (
                <div key={idx} className="flex flex-col items-center">
                  <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center mb-2">
                    <span className="text-[#1d1d1f]">•</span>
                  </div>
                  <p className="text-[#1d1d1f] text-sm">{feature}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="md:hidden relative">
        <div 
          ref={scrollRef}
          className="flex overflow-x-auto snap-x snap-mandatory scrollbar-hide"
          style={{ scrollSnapType: 'x mandatory' }}
        >
          {products.map((product, index) => (
            <div 
              key={index}
              className="flex-none w-full snap-center px-4"
              style={{ scrollSnapAlign: 'start' }}
            >
              <div className="flex flex-col items-center text-center">
              <div className="w-32 h-32 mb-6">
                  <img 
                    src={product.icon}
                    alt={product.name}
                    className="w-full h-full object-contain"
                  />
                </div>
                <span className="text-blue-600 text-sm font-medium mb-2">Included in AGT Ups</span>
                <h3 className="text-[#1d1d1f] text-xl font-semibold mb-1">{product.name}</h3>
                <p className="text-[#1d1d1f] text-sm mb-2">{product.subtitle}</p>
                <p className="text-[#1d1d1f] text-sm mb-6">{product.description}</p>
                <button className="text-blue-600 flex items-center text-sm hover:text-blue-700 transition-colors">
                  Learn more
                  <ArrowRightIcon className="ml-1 w-4 h-4" />
                </button>
                <div className="space-y-6 pt-8 border-t w-full">
                  {product.features.map((feature, idx) => (
                    <div key={idx} className="flex flex-col items-center">
                      <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center mb-2">
                        <span className="text-[#1d1d1f]">•</span>
                      </div>
                      <p className="text-[#1d1d1f] text-sm">{feature}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-6 space-x-2">
          <button
            onClick={() => handleScroll(-1)}
            className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
          >
            <ChevronLeft className="w-4 h-4" />
          </button>
          <button
            onClick={() => handleScroll(1)}
            className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
          >
            <ChevronRight className="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

const WhyAgtCard = ({ card, index, isFirst }) => (
  <motion.div
    initial={{ opacity: 0, x: 20 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ delay: index * 0.2 }}
    className={`flex-none snap-center w-[280px] md:w-[405px] h-[313px] bg-white rounded-2xl p-8 relative shadow-sm hover:shadow-md transition-shadow ${
      isFirst ? 'ml-[max(calc((100vw-1440px)/2),24px)]' : ''
    }`}
    style={{
      scrollSnapAlign: 'center',
      letterSpacing: '-0.374px',
      lineHeight: '25px',
      fontSize: '17px',
      color: '#1d1d1f'
    }}
  >
    <div className="w-12 h-12 mb-6 text-[#1d1d1f]">
      {card.icon}
    </div>
    <h3 className="text-2xl font-semibold mb-2 text-[#1d1d1f]">{card.title}</h3>
    <p className="text-[#1d1d1f] mb-4">{card.description}</p>
    <button className="absolute bottom-8 text-blue-600 hover:underline flex items-center">
      {card.link} <ChevronRight className="ml-1 w-4 h-4" />
    </button>
  </motion.div>
);

const services = [
  {
    icon: ProductSvg,
    title: "AGT Product",
    description1: "Optimize pricing and sales",
    description2: "Perfect your market fit"
  },
  {
    icon: CashFlowSvg,
    title: "AGT Cash Flow",
    description1: "Upload receipts",
    description2: "Automate financial updates"
  },
  {
    icon: SupplierSvg,
    title: "AGT Supplier",
    description1: "Find suppliers",
    description2: "AI-powered negotiations"
  },
  {
    icon: CouncilSvg,
    title: "AGT Council",
    description1: "Expert advice",
    description2: "Business decisions"
  },
  {
    icon: GrowthSvg,
    title: "AGT Growth",
    description1: "Tailored strategies",
    description2: "Scale your startup"
  }
];

const AGTUps = () => {
  const featuresRef = useRef(null);
  const whyAgtRef = useRef(null);

  const handleScroll = (ref, direction) => {
    if (ref.current) {
      const cardWidth = window.innerWidth < 640 ? 280 : 405;
      const scrollAmount = cardWidth + 32;
      
      ref.current.scrollBy({
        left: direction * scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  const featureCards = [
    {
      title: "Market Optimization",
      subtitle: "Transform your product strategy with AI-powered insights",
      category: "AGT Product",
      backgroundImage: featureDirective
    },
    {
      title: "Financial Intelligence",
      subtitle: "Automate your financial operations seamlessly",
      category: "AGT Cash Flow",
      backgroundImage: featureAcademic
    },
    {
      title: "Supplier Network",
      subtitle: "AI-powered supplier matching and negotiation",
      category: "AGT Supplier",
      backgroundImage: featureProject
    },
    {
      title: "Strategic Advisory",
      subtitle: "Expert business guidance powered by AI",
      category: "AGT Council",
      backgroundImage: featureAnalytics
    },
    {
      title: "Growth Engine",
      subtitle: "Scale your startup with data-driven strategies",
      category: "AGT Growth",
      backgroundImage: featureAdaptive
    }
  ];

  const whyAgtCards = [
    {
      icon: <BarChart className="w-full h-full" />,
      title: "Market Intelligence",
      description: "Optimize your product strategy with AI-powered market analysis and competitive insights.",
      link: "Explore market tools"
    },
    {
      icon: <CreditCard className="w-full h-full" />,
      title: "Financial Automation",
      description: "Streamline operations with instant document processing and smart forecasting.",
      link: "See financial tools"
    },
    {
      icon: <Users className="w-full h-full" />,
      title: "Supplier Network",
      description: "Find and connect with perfect suppliers through AI-powered matching.",
      link: "Explore network"
    },
    {
      icon: <Brain className="w-full h-full" />,
      title: "Expert Council",
      description: "Access AI-enhanced strategic guidance for complex business decisions.",
      link: "Get guidance"
    },
    {
      icon: <BookOpen className="w-full h-full" />,
      title: "Growth Strategy",
      description: "Scale your startup with customized growth plans and execution support.",
      link: "See growth tools"
    },
    {
      icon: <LucideGalleryVertical className="w-full h-full" />,
      title: "Comprehensive Suite",
      description: "All tools work seamlessly together in the AGT Ups ecosystem.",
      link: "View integration"
    },
    {
      icon: <Hand className="w-full h-full" />,
      title: "Enterprise Ready",
      description: "Secure, scalable solutions for growing businesses.",
      link: "See enterprise features"
    },
    {
      icon: <SmileIcon className="w-full h-full" />,
      title: "Future Proof",
      description: "Stay ahead with continuous updates and cutting-edge AI features.",
      link: "See our roadmap"
    }
  ];

  return (
    <div className="w-full min-h-screen bg-white">
      <section className="relative pt-16 lg:pt-20 mb-[150px] px-4 sm:px-6 lg:px-8">
        <div className="max-w-[1625px] mx-auto">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mb-6 md:mb-8"
          >
            <h1 className="text-4xl md:text-5xl lg:text-[80px] font-semibold text-[#1d1d1f]">
              AGT Ups
            </h1>
            <p className="text-lg md:text-xl lg:text-[28px] text-[#1d1d1f] mt-2">
              From idea to success.
            </p>
          </motion.div>

          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <motion.div 
              className="h-[763px] relative w-full rounded-[28px] overflow-hidden bg-cover bg-center"
              style={{backgroundImage: `url(${heroBg})`}}
            >
              <div className="absolute bottom-8 left-8 sm:bottom-12 sm:left-12">
                <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4">
                  <button className="w-full sm:w-auto bg-white text-[#1d1d1f] px-6 py-3 rounded-full hover:bg-white/90 text-base md:text-lg transition-colors">
                    Get Started
                  </button>
                  <button className="w-full sm:w-auto text-white hover:underline flex items-center justify-center text-base md:text-lg transition-opacity">
                    Learn more <ArrowRightIcon className="ml-2 w-4 h-4" />
                  </button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </section>

      <section className="mb-[150px] bg-white w-full py-12 lg:py-16">
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f] mb-[80px] text-center">
            Get to know AGT Ups
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-5 gap-8 lg:gap-12">
            {services.map((service, index) => (
              <ServiceCard key={index} service={service} />
            ))}
          </div>
        </div>
      </section>

      <section className="mb-[150px]">
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 mb-[80px]">
          <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f]">
            Explore features
          </h2>
        </div>

        <div className="relative">
          <div 
            ref={featuresRef}
            className="flex snap-x snap-mandatory space-x-8 overflow-x-auto scrollbar-hide pb-8"
            style={{
              scrollSnapType: 'x mandatory',
              WebkitOverflowScrolling: 'touch'
            }}
          >
            {featureCards.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.2 }}
                className={`flex-none snap-center rounded-3xl text-white relative overflow-hidden w-[280px] md:w-[405px] h-[500px] md:h-[740px] group cursor-pointer bg-cover bg-center ${
                  index === 0 ? 'ml-[max(calc((100vw-1440px)/2),24px)]' : ''
                }`}
                style={{
                  scrollSnapAlign: 'center',
                  backgroundImage: `url(${feature.backgroundImage})`
                }}
              >
                <div className="absolute inset-0">
                  <div className="absolute top-3 sm:top-6 left-3 sm:left-6 right-3 sm:right-6 text-center">
                    <span className="text-[10px] sm:text-sm font-medium text-white">
                      {feature.category}
                    </span>
                    <h3 className="text-base sm:text-2xl font-semibold text-white mt-1 sm:mt-2">
                      {feature.title}
                    </h3>
                    <p className="text-xs sm:text-lg text-white mt-0.5 sm:mt-1">
                      {feature.subtitle}
                    </p>
                  </div>
                  <button className="absolute bottom-3 sm:bottom-6 right-3 sm:right-6 w-6 sm:w-10 h-6 sm:h-10 rounded-full bg-white/10 backdrop-blur-sm flex items-center justify-center transition-all group-hover:bg-white/20">
                    <Plus className="w-3 sm:w-6 h-3 sm:h-6 text-white" />
                  </button>
                </div>
              </motion.div>
            ))}
          </div>
          <div className="absolute right-4 sm:right-6 lg:right-8 -bottom-12 flex space-x-2">
            <button
              onClick={() => handleScroll(featuresRef, -1)}
              className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
            >
              <ChevronLeft className="w-4 h-4" />
            </button>
            <button
              onClick={() => handleScroll(featuresRef, 1)}
              className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
            >
              <ChevronRight className="w-4 h-4" />
            </button>
          </div>
        </div>
      </section>
      <section className="mb-[150px] bg-white w-full py-12 lg:py-16">
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f] text-center mb-16">
            Find your perfect tool
          </h2>
          <ProductComparison />
        </div>
      </section>

      <section>
        <div className="bg-[#f5f5f7] pt-[80px] pb-[150px] mb-[150px]">
          <div className="max-w-[1440px] mx-auto">
            <div className="px-4 sm:px-6 lg:px-8 mb-[80px]">
              <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f]">
                Why choose AGT Ups?
              </h2>
              <div className="flex justify-end">
                <button className="text-blue-600 hover:underline flex items-center">
                  Compare all features <ChevronRight className="ml-1 w-4 h-4" />
                </button>
              </div>
            </div>
          </div>

          <div className="relative">
            <div 
              ref={whyAgtRef}
              className="flex snap-x snap-mandatory space-x-8 overflow-x-auto scrollbar-hide pb-8"
              style={{
                scrollSnapType: 'x mandatory',
                WebkitOverflowScrolling: 'touch'
              }}
            >
              {whyAgtCards.map((card, index) => (
                <WhyAgtCard 
                  key={index} 
                  card={card} 
                  index={index} 
                  isFirst={index === 0}
                />
              ))}
            </div>
            <div className="absolute right-4 sm:right-6 lg:right-8 -bottom-12 flex space-x-2">
              <button
                onClick={() => handleScroll(whyAgtRef, -1)}
                className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
              >
                <ChevronLeft className="w-4 h-4" />
              </button>
              <button 
                onClick={() => handleScroll(whyAgtRef, 1)}
                className="w-8 h-8 rounded-full bg-black/10 hover:bg-black/20 flex items-center justify-center"
              >
                <ChevronRight className="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-[150px] bg-white w-full py-12 lg:py-16">
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f] text-center mb-16">
            Made for your business
          </h2>
          <DeviceShowcase />
        </div>
      </section>

      <UpsEssentials />

      <section className="mb-[150px] bg-white w-full py-12 lg:py-16">
        <div className="max-w-[1440px] mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl md:text-4xl lg:text-[56px] font-semibold text-[#1d1d1f] mb-8">
            Ready to transform your business?
          </h2>
          <p className="text-lg md:text-xl text-gray-600 mb-12 max-w-3xl mx-auto">
            Join thousands of entrepreneurs who are already using AGT Ups to revolutionize their business operations.
          </p>
          <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
            <button className="w-full sm:w-auto bg-blue-600 text-white px-8 py-3 rounded-full hover:bg-blue-700 transition-colors">
              Start free trial
            </button>
            <button className="w-full sm:w-auto text-blue-600 hover:underline flex items-center justify-center">
              Contact us for enterprise pricing <ArrowRightIcon className="ml-2 w-4 h-4" />
            </button>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default AGTUps;